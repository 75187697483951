import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useFormContext, Controller } from "react-hook-form";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");

function HoursField({ field,label,type,rules }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const currentTime = dayjs().format("YYYY-MM-DDTHH:mm");

  return (
    <>
      <div className="input-group d-block">
        <label className="hrs-label">{label}</label>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name={`hours[${field.name}].${type}`} // Input name for form data
            control={control}
            rules={rules}
            render={({ field }) => {
              // console.log(field.value,field.name);
              const value = field.value ? field.value : currentTime;
              // console.log(currentTime,"currentTime");
              // console.log(value,field.name);
              return (
                <TimePicker
                  {...field}
                  // value={dayjs.utc(field.value)}
                   value={dayjs.utc(value)}
                  className="form-control w-100"
                />
              );
            }}
          />
        </LocalizationProvider>
      </div>
      {errors?.hours?.[field.name]?.[type] && (
        <span className="error">
          {errors.hours[field.name][type].message}
        </span>
      )}
    </>
  );
}

export default HoursField;

import { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, appSecretKey, baseUrl } from "../Constants";
import md5 from "md5";
import { Navigate, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';



const useSignatureGenerator = () => {
  const paramsAttacher = (params) => {
    let apiUrl = "";
    for (const [key, param] of Object.entries(params)) {
      if (Array.isArray(param)) {
        apiUrl += `&${key}=${JSON.stringify(param)}`;
      } else {
        apiUrl += `&${key}=${param}`;
      }
    }
    return apiUrl;
  };

  const orderParameters = (params) => {
    const sortedParams = {};
    Object.keys(params)
      .sort()
      .forEach((key) => {
        sortedParams[key] = params[key];
      });
    return sortedParams;
  };

  const encryptParams = (apiUrl, appSecret) => {
    // const encoder = new TextEncoder();
    // const urlBytes = encoder.encode(apiUrl);
    // return CryptoJS.HmacSHA256(urlBytes, appSecret).toString(CryptoJS.enc.Hex);
    return CryptoJS.SHA256(apiUrl + appSecret).toString(CryptoJS.enc.Hex);
  };

  const getAppSignature = (apiUrl, appId, appSecret, params) => {
    apiUrl = `${apiUrl}?appId=${appId}`;
    params = orderParameters(params);
    const paramsUrl = paramsAttacher(params);
    apiUrl = `${apiUrl}${paramsUrl}`;

    const token = encryptParams(apiUrl, appSecret);

    return token;
  };

  return { getAppSignature };
};

// const getAppSignature = () => {
//   var basepath = process.env.REACT_APP_SOCIALWIRE_URL+'api/addlogs';
//   var apiUrl = `${basepath}?appId=${process.env.REACT_APP_APPID}`;
//   queryParams['event_value'] = window.location.origin + window.location.pathname;
//   queryParams['event_type'] = 2;
//   var sortedParams = {};
//       Object.keys(queryParams)
//         .sort()
//         .forEach((key)=> {
//           sortedParams[key] = queryParams[key];
//         });

//   let paramsUrl = '';
  
//   for (const [key, param] of Object.entries(sortedParams)) {
//     if (Array.isArray(param)) {
//       paramsUrl += `&${key}=${JSON.stringify(param)}`;
//     } else {
//       paramsUrl += `&${key}=${param}`;
//     }
//   }


//   apiUrl = `${apiUrl}${paramsUrl}`;
//   console.log(apiUrl);
//   const token = crypto.HmacSHA256(apiUrl, process.env.REACT_APP_APPSECRET).toString(crypto.enc.Hex);

//   return token;
// };


export const MakeAxiosRequest = (method,parameters,url) => {
    // console.log(method,data,url,baseUrl);
    axios.defaults.baseURL=baseUrl
    const accesstoken = localStorage.getItem('accesstoken');
    // const timestamp = Date.now();
    // const key= timestamp+apiKey;
    // const encryptedBackendKey = md5(key)    
    const { getAppSignature } = useSignatureGenerator();
    const apiUrl = baseUrl+url;
    const appId = apiKey;
    const appSecret = appSecretKey;
    const params = parameters;
    const signature = getAppSignature(apiUrl, appId, appSecret, params);
    const storedIP = localStorage.getItem('ip')

    
    return new Promise((response) => {
        axios({
            method,
            url,
            data:{
                "apiInfo":{
                    "ip": storedIP,
                    "devicetoken": "abd53d1e3f63b899df0f57ce837d46d3d60f25dc916e607a025c9d5e689b9e06",
                    "devicetype": "web",
                    "tokenstatus": "1",                  
                },
                "parameters":parameters
            },
            headers:{
                "appid":apiKey,
                "appsignature":signature,
                "accesstoken":accesstoken
            }
        }).then((res) => {
            // console.log(res.data);
            if(res.data.status=='-1'){
                localStorage.removeItem('accesstoken');
                localStorage.removeItem('user');
                // navigate('/login');
                // return <Navigate to="/login" />
                window.location.href='/login';

            }
            if(res.data.status=='-3'){
              alert(res.data.message)
              localStorage.removeItem('accesstoken');
              localStorage.removeItem('user');
              // navigate('/login');
              // return <Navigate to="/login" />
              window.location.href='/login';

          }
            response(res.data);
        }).catch((err) => {
            console.log(err.message);
            response(err);
        })
    })
}

export const MakeFileUpload = (method,parameters,url) => {
  // console.log(method,data,url,baseUrl);
  axios.defaults.baseURL=baseUrl
  const accesstoken = localStorage.getItem('accesstoken');

  // const timestamp = Date.now();
  // const key= timestamp+apiKey;
  // const encryptedBackendKey = md5(key)

  const { getAppSignature } = useSignatureGenerator();

  const apiUrl = baseUrl+url;
  const appId = apiKey;
  const appSecret = appSecretKey;
  const params = parameters;

  const signature = getAppSignature(apiUrl, appId, appSecret, params);
 
  return new Promise((response) => {
      axios({
          method,
          url,
          data:parameters,
          headers:{
              "content-type": "multipart/form-data",
              "appid":apiKey,
              "appsignature":signature,
              "accesstoken":accesstoken
          }
      }).then((res) => {
          console.log(res.data);
          if(res.data.status=='-1'){
              localStorage.removeItem('accesstoken');
              // navigate('/login');
              // return <Navigate to="/login" />
              // window.location.href='/login';

          }
          response(res.data);
      }).catch((err) => {
          console.log(err.message);
          response(err);
      })
  })
}

export const MakeFileDownlaod = (method,parameters,url) => {
  // console.log(method,data,url,baseUrl);
  axios.defaults.baseURL=baseUrl
  const accesstoken = localStorage.getItem('accesstoken');

  // const timestamp = Date.now();
  // const key= timestamp+apiKey;
  // const encryptedBackendKey = md5(key)
 const { getAppSignature } = useSignatureGenerator();
    const apiUrl = baseUrl+url;
    const appId = apiKey;
    const appSecret = appSecretKey;
    const params = parameters;
    const signature = getAppSignature(apiUrl, appId, appSecret, params);
    const storedIP = localStorage.getItem('ip')
    
    return  fetch(baseUrl+'/'+url, {
        method: 'post',
        mode: 'no-cors',
        Accept:'application/octet-stream',
        body:JSON.stringify({
                "apiInfo":{
                    "ip": storedIP,
                    "devicetoken": "abd53d1e3f63b899df0f57ce837d46d3d60f25dc916e607a025c9d5e689b9e06",
                    "devicetype": "web",
                    "tokenstatus": "1",                  
                },
                "parameters":parameters
            }),
            headers:{
                "appid":apiKey,
                "appsignature":signature,
                "accesstoken":accesstoken
            }
    })
    .then(response => {console.log(response); return response.blob()})
    .then(blob => {
     //console.log(blob);
      const url = window.URL.createObjectURL(new Blob([blob]),{ type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'hv.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
//      let reader =new FileReader();
//      reader.onloadend = function(){
//          console.log(reader.result);
//      }
//      reader.readAsDataURL(blob);
    })
    .catch(error => console.error('Error downloading file:', error));
  
}

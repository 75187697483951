import React, {useState , useEffect,useRef} from 'react'; 
import { MakeAxiosRequest } from '../../utils/handler';
import { useForm } from "react-hook-form";
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";

const ListingComponent = ({ payoutData, agentKey,handlePageChange,handleSelectChange,role }) => {

  const [currentPayoutamount, setCurrentPayoutamount] = useState();
  const payouts = payoutData?.payouts || [];
  const filters = payoutData?.filters || [];
  const totalCount = payoutData?.totalcount;
  const [isTableLoaded, setisTableLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const formRef = useRef(null); 
  const getPayoutAct = async () => {
    formRef.current.reset();
    $('#payout-modal').modal('show');    
    try {
      const payoutDet = await MakeAxiosRequest("post", {}, `/agent/${agentKey}/payout/create`);     
      setCurrentPayoutamount(payoutDet.data.currentpayout); 
      // setIsLoaded(true);
    } catch (error) {
        console.error(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors }, 
    reset 
  } = useForm();


  
  const handleFilterChange = (type, value) => {
    handleSelectChange('payouts',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const agentData =(type='') =>{
    setisTableLoaded(totalCount !== undefined);  
    if(type==1){
      handleFilterChange(filterData);
    } 
   
   };

  useEffect(() => {
    agentData()
  }, [totalCount]);
  


  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('payouts',newOffset,type,value);   
    setCurrentPage(newOffset);
  };



  const payoutSubmit = async () => {
    try {
      const description = $("#description").val();
      const newPayout = $("#newPayout").val();  
      const payoutDet = await MakeAxiosRequest("post", {"amount": newPayout,"description":description}, `/agent/${agentKey}/payout/store`);
      if (payoutDet.status===1) {
        Swal.fire({icon: 'success',  title: 'Success!', customClass: {  confirmButton: 'btn btn-primary'   },
          text: payoutDet.message
        });  
        $('#payout-modal').modal('hide');     
        onPageChange(1);            
      }else{
        Swal.fire({ icon: 'error', title: 'Error!',   customClass: {  confirmButton: 'btn btn-primary' }, text: payoutDet.message  }); 
      }
    } catch (error) {     
      console.error(' error:', error);
    }
  };
  
 
  const columns = [
        {
          name: <div className="datatable-hd"><h6>Transaction Type</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">          
             
             
          
                  <p> {row.transaction_type}</p>                 
              
                  
            </div>),      
        },
        {
          name: <div className="datatable-hd"><h6>Paid To</h6></div>,
          selector: row => ( <div data-title="" className="numeric data-table-sec" > <div><p>{row.paid_to || '-'} </p> </div>  </div> ),
          omit:  (role === 'Agent' ? true : false ),
        },
        {
            name: <div className="datatable-hd"><h6>Description</h6></div>,
            selector: row => (
              <div data-title="" className="numeric data-table-sec">
                <p>{row.payout_details}</p>              
              </div>),           
        },
        {
          name: <div className="datatable-hd"><h6>Transaction Date</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">            
             <span>{format(new Date(row.created_at), 'dd/MM/y')}</span>
            </div>
          ),    
      },      
      {
          name:<div className="datatable-hd"><h6>Amount</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">      
              <p>{row.amount}</p>              
          </div>),   
      },  
      
  ];

  return (
    <>
     <div class="tab-pane fade show active" id="payouts-tab-pane" role="tabpanel" aria-labelledby="payouts-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
      <div class="col-lg-6 mb-3 text-center text-lg-start">
            <h4 class="mt-3">Payouts</h4>
            <p class="mb-0 tab-hd-p">The payment amount to the agent will be shown on this page.</p>
          </div>
          <div class="col-lg-6 mb-3">
            <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            {filters?.map((filter, index) => (
                <div className="sort-sec my-2 me-2" key={index}>
                  <p className="me-1">{filter.title} : </p>
                  <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                    {filter.options?.map( option => 
                      <option value={option.value} >{option.option}</option>
                    )}                        
                  </select>
                </div>
              ))}
            
              <button type="button" class="btn btn-primary my-2" data-bs-toggle="modal" data-bs-target="#payout-modal"  onClick={() => getPayoutAct()}>
                Create Payout
              </button>
            </div>
          </div>
        </div>
        <div className="">          
            <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}  tableData={payouts}  type ={'payouts'} isTableLoaded={isTableLoaded}/>    
        </div>
       
      </div>


        {/* Payout Modal */}
        <div class="modal fade" id="payout-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 mt-4">
                            <div class="text-center">
                              <h5>Create Payout Amount</h5>
                              <p class="gray">Create a payout amount for this agent</p>
                            </div>                            
                        </div>
                      </div>
                      <form class="row mt-4" ref={formRef}    onSubmit={handleSubmit(payoutSubmit)}>
                        <div class="col-md-6 col-lg-5 mb-3">
                          <div class="card ovrview-cards">
                            <div class="card-body">
                              <p>Current  Payout</p>
                              <h3>₹{currentPayoutamount || '0'}</h3>
                              {/* <span>Last Updated : 24 Nov 2023</span> */}
                            </div>
                          </div>
                          </div>
                          <div class="col-md-6 col-lg-7">
                            <div class="row ">
                              <div class="col-12 mb-3">
                                <label for="inputEmail4" class="form-label">New Payout</label>
                                <div class="input-group icon-form-lft">
                                  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-indian-rupee-sign"></i></span>
                                  <input type="text" class="form-control" name="newPayout"  id="newPayout" placeholder="" 
                                  aria-label="Username" 
                                  aria-describedby="basic-addon1"
                                  {...register("newPayout", {
                                    required: "Please enter amount",
                                    pattern: {
                                      value: /^\d+(\.\d+)?$/,
                                      message: "Please enter valid amount",
                                    },
                                    validate: {
                                      notZero: value => parseFloat(value) !== 0 || "Amount cannot be 0",
                                    },
                                  })}
                                  
                                  />
                                 

                                </div>
                                {errors.newPayout && (
                                    <span className="error">{errors.newPayout.message}</span>
                                  )}

                              </div>
                              <div class="col-12 mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                                <textarea
                                    class="form-control"
                                    id="description"
                                    name="description"
                                    rows="3"
                                    {...register("description", {
                                      required: "Please enter the description.",
                                      minLength: {
                                        value: 20,
                                        message: "Description must be at least 20 characters long.",
                                      },
                                    })}
                                  />
                                {errors.description && (
                                  <span className="error">{errors.description.message}</span>
                                )}

                              </div>
                            </div>
                            
                          </div>
                          <div class="modal-footer b-0">
                            <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" class="btn btn-primary" >Submit</button>
                          </div>
                      </form>
                    </div>
                  
                  </div>
                </div>
              </div>
    </>
  );
};

export default ListingComponent;

import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import InputField from "../../Components/Inputs/InputField";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { FormProvider, useForm,Controller } from "react-hook-form";
import TextArea from "../../Components/Inputs/TextArea";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";

function AddReview({ show, handleClose, businessKey,fetchData }) {
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const methods = useForm();

  const { handleSubmit, reset, setValue, formState: { errors },getValues,control} = methods;

  // const fetctDetails = async () => {
  //   try {
  //     const response = await MakeAxiosRequest("post", {}, "/business/94d2d75ef3/review/store");
  //     // console.log(response)
  //     if (response.status == 1) {
  //       console.log(response)
  //     } else {
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     console.error("Error", error);
  //   }
  // };

 
  const create = async (data) => {

    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest(
        "post",
        data,
        `/business/${businessKey}/review/store`
      );
      // console.log(response);
      if (response.status == 1) {
        reset();
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        // userData(1, perPage);
        fetchData(1);
        // alert(response.message);
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  // useEffect(() => {
  //   fetctDetails();
  // }, [])

  useEffect(() => {
    setSubmitDisabled(true);
    reset();
    return () => {
      setSubmitDisabled(true);
      reset();
    };
  }, [show]);


  const ratingChanged = (newRating) => {
    setSubmitDisabled(false);
    setValue("rating", newRating);
  };

  return (
    <>
      <Modal
        show={show}
        // onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(create)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="text-center">
                    <h5>Add a Review</h5>
                    <p className="gray">
                      Add a new review for this business on Aladinz app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 mb-3">
                  <label htmlFor="inputEmail4" className="form-label d-block">
                    How would you rate this business?
                  </label>
                  <Controller
                    name="rating"
                    control={control}
                    render={({ field }) => (
                      <ReactStars
                        count={5}
                        value={field.value}
                        onChange={(newRating) => {
                          field.onChange(newRating);
                          ratingChanged(newRating);
                        }}
                        size={24}
                        activeColor="#ffd700"
                      />
                    )}
                    rules={{ required: 'Please select a rating' }}
                  />
                 
                  {errors.rating && (
                                  <span className="error">
                                    {errors.rating.message}
                                  </span>
                                )}
                 
                </div>
                <TextArea
                  className="col-12 mb-3"
                  name="comment"
                  label="Add Review"
                  rules= {{ required: "Please enter review" }}
                />
              
              </div>
            </div>

            <div className="modal-footer b-0">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Add Review
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}

export default AddReview;

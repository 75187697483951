import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from "date-fns";


function BillingInfo({billingInfoData,handlePageChange,handleSelectChange}) {
  const columns = [
    {
      name: (
        <div className="datatable-hd">
          <h6>Agent Name</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
            <div className="profile-sec">
              <img src={row.agent_image} alt="" />
              <div>
                <p>{row.agent_name}</p>
              </div>
            </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Amount</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
        >
          <div>
            <p>{row.amount} </p>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Paid On</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.created_at), "dd/MM/y")}</p>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Status</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
        >
          <div>
            {/* <p>{row.status} </p> */}
            <span className="status-tag status-tag-active">Paid</span>                            
          </div>
        </div>
      ),
    },

    {
      name: (
        <div className="datatable-hd">
          <h6>Next Renewal Date</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{row.renewal_date}</p>
        </div>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const [isTableLoaded, setisTableLoaded] = useState(true);
  const totalCount = billingInfoData?.totalcount;


  useEffect(() => {    
   fetchData();
 }, [totalCount]);

 const fetchData =() =>{   
  setisTableLoaded(totalCount !== undefined); 
 
 
 };
 

  const handleFilterChange = (type, value) => {
    handleSelectChange('billinginfo',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('billinginfo',newOffset,type,value);   
    setCurrentPage(newOffset);
  };

  return (
    <div className="tab-pane fade show active" id="billinginfo-tab-pane" role="tabpanel" aria-labelledby="billing-tab" tabIndex="0">
                            <div className="row d-flex align-items-center justify-content-center flex-wrap ">
                              <div className="col-lg-12 mb-3 text-center text-lg-start">
                                <h4 className="mb-0">Billing Info</h4>
                              </div>
                              {/* <div className="col-lg-8 mb-3">
                                <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
                                  <div className="sort-sec my-2 me-2">
                                    <p className="me-1">Sort By : </p>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Newest</option>
                                      <option value="1">Oldest</option>
                                      <option value="2">A - Z</option>
                                      <option value="3">Z - A</option>
                                    </select>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            <div className="">          
                              <DataTableList  pageChange={onPageChange}    totalCount={billingInfoData.totalcount}  columns={columns}  tableData={billingInfoData.businessBilling}  type ={'billing info'} isTableLoaded={isTableLoaded} />    
                          </div>  
      
       
                                  {/* <table className="table table-hover table-condensed">
                                    <thead className="">
                                      <tr>
                                        <th className="numeric">Agent Name</th>
                                        <th className="numeric">Amount</th>
                                        <th className="numeric">Paid On</th>
                                        <th className="numeric">Status</th>
                                        <th className="numeric">Next Renewal Date</th>
                                       
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        
                                        <td data-title="Name" className="align-middle">
                                          <div className="profile-sec">
                                            <img src="img/dummy-profile.jpg" alt=""/>
                                            <p>Jain Thomas</p>
                                          </div>
                                        </td>
                                        <td data-title="Amount" className="numeric align-middle">
                                          <p>₹800</p>
                                        </td>
                                        <td data-title="Paid On" className="numeric align-middle">
                                          <p>04/02/2023</p>
                                        </td>
                                        <td data-title="Status" className="numeric align-middle">
                                          <span className="status-tag status-tag-active">Paid</span>
                                        </td>
                                        <td data-title="Next Renewal Date" className="numeric align-middle">
                                          <p>04/03/2023</p>
                                        </td>
                                       
                                      </tr>
                                    </tbody>
                                  </table> */}

                             
                          </div>
  )
}

export default BillingInfo
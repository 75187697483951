import React, {useState, useEffect} from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import DataTableList from "../../../Components/Datatablelist/Datatablelisting";
import { MakeAxiosRequest } from '../../../utils/handler';
import noRecords   from '../../../Assets/Images/no-records.png'
import dummyProfile  from "../../../Assets/Images/dummy-profile.jpg"
import loaderFile   from '../../../Assets/Images/loader.gif';
import dummyBusiness from "../../../Assets/Images/business-img.png";
import { useBreadcrumb } from "../../../Components/Breadcrumbs/BreadcrumbContext";
import { format } from 'date-fns';

import { CSVLink } from 'react-csv';
import UserRowActions from '../../../Components/Datatablelist/Tablerowactions';


function Listing({searchValue,setsearchValue}) {
    // const [selectedOption, setSelectedOption] = useState('businesses');
     const navigate = useNavigate();
     const params = useParams();
    //  
     const selectedOption = params.type || 'businesses';
     const sortby = params.sortby || "highest";

    // setSelectedOption(type)
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [getsearchReports, setSearchReports] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [isTableLoaded, setisTableLoaded] = useState(true);
    const [filters, setFilters] = useState([]);
    const [searchTypes, setSearchTypes] = useState([]);
    
    const { setBreadcrumbsData } = useBreadcrumb();
    const [listParams, setListParams] = useState({
      offset: "",
      sortBy: sortby,
      type: selectedOption,
    });

    useEffect(() => {
      fetchData(1, perPage);
    }, [perPage,listParams,searchValue])

    const columns = [    
    
    
      {
        name: <div className="datatable-hd"><h6>{selectedOption === 'businesses' ? ( 'Business'   ) : ( 'Category' )}</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
            {selectedOption === 'businesses'? ( 
              <UserRowActions  row={{ primaryData: row.business, imagePath :row.business_image ||  dummyBusiness,          
                viewUrl: `/business/${row.business_uuid}` 
              }}/> 
            ) : 
            (<p>{row.category}</p> )}
          </div>
        ),
      },
      
      {
        name: <div className="datatable-hd"><h6>{selectedOption === 'businesses' ? ( 'Agent'   ) : ( 'No of Businesses' )}</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
              {selectedOption === 'businesses'? ( 
                <UserRowActions  row={{
                  primaryData: row.agent,
                  imagePath :row.agent_image  || dummyProfile,
                  viewUrl:`/agent/${row.agent_user_uuid}`
                }} />
            ) : 
            (<p className='search-no'>{row.no_of_businesses}</p> )}
             
          </div>
        ),
      },
      

      {   

          name: <div className="datatable-hd"><h6>Category</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">
       
                <p>{`${row.category}`}</p>
           
          </div>
          ),
          omit:  (selectedOption === 'businesses' ? false : true ),
        

      },
      {
        // ...(selectedOption === 'category' && {
          name: <div className="datatable-hd"><h6>Most Searched Business</h6></div>,
          selector: row => (
            row.show_business === '1' ? (
              <UserRowActions  row={{ primaryData: row.business_name, imagePath :row.business_image_path ||  dummyBusiness,          
                viewUrl: `/business/${row.business_uuid}` 
              }}/>  
            ) : ('--')
          ),
          omit:  (selectedOption === 'category' ? false : true ),
        // }),
      },
      
   
      {
        name: <div className="datatable-hd"><h6>No of Searches</h6></div>,
        selector: row => (
          <div data-title="" className="">
                <p className='search-no'>{row.no_of_searches}</p>
          </div>        
        ),
      },  

    ]; 

 


  // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData([     
        { label: "Reports" },
        { label: "Search Reports"},
        { label: "Listing"},
       
      ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 
  

  const fetchData = async () => {      
    try {
      
       const response = await MakeAxiosRequest("post", {...listParams, searchValue}, "/searchreports");     
       setSearchReports(response.data.searchreports); 
       setIsLoaded(true);
       setisTableLoaded(true);
       setTotalRows(response.totalcount);
       setFilters(response.data.filters); 
       setSearchTypes(response.data.types); 
       //setExportedData(response.data.searchreports); 
    } catch (error) {      
       console.error('Error fetching data:', error);
    }
 };


 const handlePageChange = page => {
  const newOffset = (page - 1) ;
  setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));
  
}


  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  }
  const handleSelectChange = (filterName,value) => {   
    setisTableLoaded(false);
    if(filterName==='sortBy')
      navigate('/searchreports/'+listParams.type+'/'+value);  
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };

  const handleClickChange = (filterName,value) => { 
    navigate('/searchreports/'+value+'/'+listParams.sortBy); 
    setsearchValue('');
    setisTableLoaded(false);
    //setSelectedOption(value);
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };

  const formattedDate = format(new Date(), 'dd/MM/yyyy-h:mm a');   
  const csvFileName = `${selectedOption === 'businesses' ? `search-reports-businesses-${formattedDate}.csv` : `search-reports-category-${formattedDate}.csv`}`;


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleStartDateChange = (date) => {
    setStartDate(date);  
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);   
  };


  //Function to format the date to IST
  const formatToIST = (date) => {
    const options = { timeZone: 'Asia/Kolkata' };
    return date.toLocaleString('en-US', options);
  };

  const handleDateChange = () => {
    let errMsg = '';
    if (startDate && endDate) {
      setIsLoaded(false);
      setDatePickerError(null);
  
      let formattedStartDate = formatToIST(startDate);
      let formattedEndDate = formatToIST(endDate);
  
      formattedStartDate = format(new Date(formattedStartDate), 'y-MM-dd');
      formattedEndDate = format(new Date(formattedEndDate), 'y-MM-dd');
  
      setListParams((prevParams) => ({
        ...prevParams,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        offset:'0'
      }));
    } else {
      if(!startDate && !endDate){
        errMsg = 'start date and end date';
      }else  if (!startDate) {
        errMsg = 'start date';
      } else if (!endDate) {
        errMsg = 'end date';
      }
      setDatePickerError(
        <span className="error">Please choose the {errMsg}</span>
      );
    }
  };
  






  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
    <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-xl-5 mb-3">
            <div className="d-flex justify-content-center justify-content-xl-start">
                <ul className="sort-btn-group">
                   {searchTypes?.flatMap((filter) => filter.options)?.map((option, optionIndex) => (
                      <li key={optionIndex}>
                        <a  className={`btn tabview ${option.value === selectedOption ? 'active' : ''}`}   onClick={() => handleClickChange(searchTypes[0].type, option.value)}>
                          {option.option}  
                        </a>
                      </li>
                    ))}
                </ul>
            </div>
          </div>
        <div className="col-xl-7 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-xl-end flex-wrap"> 
          
          {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option => 
                  <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                )}                        
              </select>
            </div>
          ))}
        {getsearchReports && getsearchReports.length > 0 && (
          <CSVLink
            data={selectedOption === 'businesses'
              ? getsearchReports.map(item => ({
                  Business: item.business,
                  Agent: item.agent||'--',
                  Category: item.category ||'--',
                  'No of Searches': item.no_of_searches ||'--',
                }))
              : getsearchReports.map(item => ({
                  Category: item.category,
                  'No of Businesses': item.no_of_businesses ||'--',
                  'No of Agents': item.no_of_agents ||'--',
                  'No of Searches': item.no_of_searches ||'--',
                }))
            }
            filename={csvFileName}
            className="btn btn-primary my-2"
          >
            Export
          </CSVLink>
        )} 

              {/* <Button className="btn btn-primary my-2" onClick={() => exportReport()}> Export   </Button>   */}
          </div>
          </div>
        </div>
        <div className="">  
       
       <DataTableList  pageChange={handlePageChange}     totalCount={totalRows}  columns={columns} 
        tableData={getsearchReports}   type ={'search reports'} isTableLoaded ={isTableLoaded} addclass={'search-reports-list'}/>    
    
      </div>
      
      
      </>
    );
  }
}

export default Listing;

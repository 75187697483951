import React, {useState, useEffect} from 'react';

import { MakeAxiosRequest } from '../../utils/handler';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { Button } from 'react-bootstrap';
import CreateEditModal from './CreateEditModal';
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import loaderFile   from '../../Assets/Images/loader.gif';
import { useNavigate, useParams } from 'react-router-dom';

function Listing({searchValue,setsearchValue}) {

  const navigate = useNavigate();
  const params = useParams();
  const sortby = params.sortby || "newest";

const columns = [ 
  {
  
    name: <div className="datatable-hd" ><h6>Subscription Name</h6></div>,
    selector: row => ( <div data-title="" className="numeric data-table-sec" > <div className=''><p>{row.subscription_name} </p> </div>  </div> ),

  },
  {
 
    name: <div className="datatable-hd"><h6>Amount</h6></div>,
    selector: row => (<div data-title="" className="numeric data-table-sec" > <p>₹{row.amount}</p> </div> ),   
  },
  {
    name: 'Offers Available',
    selector: row => ( <div data-title="" className="numeric  data-table-sec" > <p>{row.no_of_offers}</p> </div> ),   
  },

  {
    name: 'Tenure Type',
    selector: row => ( <div data-title="" className="numeric  data-table-sec" > <p>{row.tenure_period} {row.tenure_type}</p> </div> ),   
  },
  
  {
      name: <div className="datatable-hd"><h6>Created By</h6></div>,
      selector: row => ( <div data-title="" className="numeric data-table-sec"> <p>{row.created_by}</p><span>{format(new Date(row.created_at),  'dd/MM/y')}</span> </div>),   
  },
  {
    name: <div className="datatable-hd"><h6>Updated By</h6></div>,
    selector: row => (
      <div data-title="" className="numeric data-table-sec">
      {row.updated_at && 
        <>
       <p>{row.updated_by}</p>
       <span>{format(new Date(row.updated_at),  'dd/MM/y')}</span>
        </>}
      </div>),   
},

{
 
 
  selector: row => (
    <div data-title="" className="numeric data-table-sec">
      <div className="d-flex justify-content-end">
        <div className="btn-group option-btn">
          <a href="javascript:void(0)" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
            <i className="fa-solid fa-ellipsis"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li><a href="javascript:void(0)" className="dropdown-item"  onClick={() => handleshowEdit(row)}><i className="fa-solid fa-pen me-2"></i>Edit</a></li>
            {
              // row.deleted_at !== null ? (
              //   // <li>
              //   //   <a className="dropdown-item success" onClick={() => changeStatus(row.subscription_plan_uuid, 'activate')}><i className="fa-solid fa-square-check me-2"></i>Approve </a>
              //   // </li>
              // ) : (
                <li>
                  <a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(row.subscription_plan_uuid, 'delete')}> <i className="fa-regular fa-trash-can me-2"></i>Delete </a>
                </li>
              //)
            }

           
          </ul>
        </div>
      </div>
    </div>
  ),
  
  
  
  
  
  
},
    
];


const changeStatus = async (subscriptionKey, status) => {
  try {
    const result = await Swal.fire({
      title: 'Are you sure?',
      html: `Are you sure you want to ${status} this subscription?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${status}`,
      customClass: { confirmButton: 'btn btn-primary' },
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const res = await MakeAxiosRequest(
            'post',
            { "status": status },
            `subscription/${subscriptionKey}/changestatus`
          );
          if (res.status === 1) {
            return true;
          } else {
            throw new Error(res.message);
          }
        } catch (error) {
          console.error(error); // Log the error
          Swal.fire({
            icon: 'warning',
            title: 'Request Failed!',
            customClass: { confirmButton: 'btn btn-primary' },
            text: ` ${error.message}`,
          });
          return false; 
        }
      },
    });

    if (result.isConfirmed && result.value === true) {
      $('#tranfer-account').modal('hide');
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        customClass: { confirmButton: 'btn btn-primary' },
        text: 'Deleted successfully.',
      });
      fetchData();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      customClass: { confirmButton: 'btn btn-primary' },
      text: 'An error occurred while changing the status.',
    });
  }
};







 //const playerInstance = new DotLottiePlayer();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [isTableLoaded, setisTableLoaded] = useState(true);
  const [category, setCategory] = useState("");
  const [filters, setFilters] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [listingData, setlistingData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all');
  const { setBreadcrumbsData } = useBreadcrumb();
  const [listParams, setListParams] = useState({
    offset: "0",
    status: "active",
    sortBy: sortby,
  });
 
  // Breadcrumbs
  const updateBreadcrumbs = () => {
    setBreadcrumbsData([
   
      { label: "Subscriptions" },
      { label: "Listing" },
   
    ]);
  };  
  useEffect(() => {
    updateBreadcrumbs();
  }, []); 

  const fetchData = async () => {    
    const res = await MakeAxiosRequest("post", {...listParams,searchValue}, "/subscriptions");    
    setIsLoaded(true);
    setisTableLoaded(true);  
    setItems(res.data.subscriptionplans);     
    setFilters(res.data.filters); 
    setTotalRows(res.data.activecount);   
  }
  useEffect(() => {
    fetchData(1, perPage);
  }, [listParams, searchValue, perPage]);


  const handlePageChange = page => {
    const newOffset = (page - 1) ;
    setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));    
  }

  const handleResetPagination = () => {
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSelectChange = (filterName,value) => {   
    setsearchValue('');
    if(filterName==='sortBy')
      navigate('/subscriptions/'+value);
    setisTableLoaded(false);  
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };


  const [isEdit, setisEdit] = useState(false);
  
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState({});
  const [showEdit, setshowEdit] = useState(false);

  const handleShow = () => {
    setisEdit(false)
    setshowEdit(true)
    setSubscriptionPlanDetails({})
   
  };


  const handleshowEdit = (subscription) => {
    setisEdit(true)
    setshowEdit(true)
    setSubscriptionPlanDetails(subscription)
   
  };
  const handleCloseEdit = () => setshowEdit(false);




  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
        <div className="row d-flex align-items-center flex-wrap ">
          <div className="col-xl-5 mb-3">
            <div className="d-flex justify-content-center justify-content-xxl-start">              
            </div>
          </div>
        <div className="col-xl-7 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-md-end flex-wrap">
          {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option => 
                  <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                )}                        
              </select>
            </div>
          ))} 

          <Button className="btn btn-primary my-2" onClick={handleShow}>
        Create
      </Button>

          </div>
          </div>
        </div>
        <div className="">  
       
       <DataTableList  pageChange={handlePageChange}   paginationResetDefaultPage={resetPaginationToggle}   totalCount={totalRows}  columns={columns}  tableData={items}   type ={'subsciptions'} isTableLoaded ={isTableLoaded}/>    
    
      </div>
    
           
              <CreateEditModal {...{ showEdit, handleCloseEdit,isEdit,subscriptionPlanDetails,fetchData,perPage,handlePageChange ,handleResetPagination}} />
      </>
    );
  }
}

export default Listing;

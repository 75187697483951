import React, {useState , useEffect} from 'react';   
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from 'date-fns';
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import loaderFile   from '../../Assets/Images/loader.gif';
const ListingComponent = ({ followingData,handlePageChange }) => {

  const totalCount = followingData?.totalcount;
  const following =  followingData?.following  || [];

  const [isTableLoaded, setisTableLoaded] = useState(true);

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    handlePageChange('following',newOffset);  
   
  };


 
  const fetchData = () => {  
    setisTableLoaded(totalCount !== undefined);   
  };
  
  useEffect(() => {
    fetchData();
  }, [totalCount]);

  const columns = [
 
  {
    
      name: <div className="datatable-hd"><h6>Business Name</h6></div>,
      selector: row => ( 
        <UserRowActions  row={{ primaryData: row.business_name,
          imagePath :row.profile_img,         
          viewUrl: `/business/${row.business_uuid}`  }}/> 
      )
  
  },
  {
    
    name: <div className="datatable-hd"><h6>Phone Number</h6></div>,
    selector: row => ( <div data-title="" className="numeric data-table-sec" style={{ width: '200px' }}> <div><p>{row.contact_number} </p> </div>  </div> ),

  },
  {
      
    name: <div className="datatable-hd"><h6>Business Location</h6></div>,
    selector: row => ( <div data-title="" className="numeric data-table-sec" style={{ width: '200px' }}> <div><p>{row.business_location || '--'} </p> </div>  </div> ),

  },
  
    {
      name: <div className="datatable-hd"><h6>Followed On</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
        <p>{format(new Date(row.created_at), 'dd/MM/y')}</p>
      
      </div>),   
  },
  
 
    
    
  
      
  ];

  return (
    <>
     <div class="tab-pane fade following-tablist show active" id="following-tab-pane" role="tabpanel" aria-labelledby="categories-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
        <div class="col-12 mb-3 text-center text-lg-start">
          <h4 class="mt-3">Following</h4>
          <p class="mb-0">You can see the details of all the businesses that this user follows. </p>
        </div>
        </div>
        <div className="">          
          <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}  tableData={following}  type ={'following list'} isTableLoaded={isTableLoaded} />    
        </div>
       
      </div>
    </>
  );
};


export default ListingComponent;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { MakeAxiosRequest,MakeFileDownlaod} from "../../utils/handler";
import Swal from "sweetalert2";
import { format } from "date-fns";
import AddDocument from "./AddDocument";
import axios from "axios";
import { Modal } from "react-bootstrap";

const KycList = ({
  KycData,
  handlePageChange,
  handleSelectChange,
  agentKey,
}) => {
  const filters = KycData?.filters || [];
  const listData = KycData["documents"];
  const totalCount = KycData?.totalcount;

  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    type: "sortBy",
    value: "newest",
  });
  const [isTableLoaded, setisTableLoaded] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleFilterChange = (type, value) => {
    handleSelectChange("kyc", type, value, currentPage);
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
    const newOffset = pageNumber - 1;
    const type = filterData.type;
    const value = filterData.value;
    handlePageChange("kyc", newOffset, type, value);
    setCurrentPage(newOffset);
  };

  const fetchData = (type = "") => {
    setisTableLoaded(totalCount !== undefined);
    if (type === 1) {
      handleFilterChange(filterData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [totalCount]);
 
  const fetchAndDownloadFromS3 = async (urlfile, filemimetype,filename) => {
      
      const base64Data = await MakeAxiosRequest( 'post',  {"urlfile":urlfile,"filemimetype":filemimetype,"filename":filename},`agent/download`);
      // Convert Base64 to Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: filemimetype });

        // Create Blob URL
        const blobUrl = window.URL.createObjectURL(blob);
    
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
//      let reader =new FileReader();
//      reader.onloadend = function(){
//          console.log(reader.result);
//      }
//      reader.readAsDataURL(window.atob(res));
//      
//      
//      fetch(url, {
//      mode: 'no-cors',Accept:'application/pdf'})
//    .then(response => {console.log(response); return response.blob()})
//    .then(blob => {
//     //console.log(blob);
//      const url = window.URL.createObjectURL(new Blob([blob]),{ type: 'application/pdf' });
//      const link = document.createElement('a');
//      link.href = url;
//      link.setAttribute('download', filename);
//      document.body.appendChild(link);
//      link.click();
//      link.parentNode.removeChild(link);
////      let reader =new FileReader();
////      reader.onloadend = function(){
////          console.log(reader.result);
////      }
////      reader.readAsDataURL(blob);
//    })
//    .catch(error => console.error('Error downloading file:', error));
//    try {
////      const response = await axios.get(urlfile, { responseType: "blob" },{headers: {
////        'Access-Control-Allow-Origin': '*'
////      }});
////      const blob = new Blob([response.data],{ type: 'application/octet-stream' });
////      const url = window.URL.createObjectURL(blob);
//      const link = document.createElement("a");
//      link.href = urlfile;
//      link.setAttribute("download", filename); // Change the filename as needed
//      document.body.appendChild(urlfile);
//      link.click();
//      document.body.removeChild(urlfile);
//    } catch (error) {
//      console.error("Error fetching or downloading file:", error);
//    }
  };

  // const downloadFile = async (fileUrl,filename) => {
  //   const response = await MakeAxiosRequest(
  //     "post",
  //     {},
  //     `https://demoserver22.icwares.com/aladinz/adminapi/dev/agent/document/6f27c2a8eb/download`
  //   );
  //   // fetch(fileUrl)
  //   //   .then(response => response.blob())
  //   //   .then(blob => {
  //   //     const url = window.URL.createObjectURL(new Blob([blob]));
  //   //     const link = document.createElement('a');
  //   //     link.href = url;
  //   //     link.setAttribute('download', filename); // Change file_name and ext accordingly
  //   //     document.body.appendChild(link);
  //   //     link.click();
  //   //     link.parentNode.removeChild(link);
  //   //   })
  //   //   .catch(error => console.error('Error downloading file: ', error));
  // };

  const columns = [
    // {
    //   selector: row => (
    //     <div data-title="" className="numeric data-table-sec">

    //     </div>),
    // },
    {
      name: (
        <div className="datatable-hd">
          <h6>File Name</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <div className="d-flex align-items-center">
            <div className="profile-sec file-thumb">
              <img className="" src={row.thumbnail} />
            </div>
            <p>{row.original_name}</p>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Added By</h6>
        </div>
      ),
      selector: (row) => (
        <UserRowActions
          row={{
            primaryData: row.added_by_name,
            imagePath: row.added_by_image,
            viewUrl: `/user/${row.added_by_uuid}`,
          }}
        />
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Added on</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.created_at), "dd/MM/y")}</p>
        </div>
      ),
    },
    {
      selector: (row) => (
        <div data-title="" className="numeric scrolview data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a
                href=""
                className="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              >
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                {/* <li><a href={row.doc_path} target="_blank" className="dropdown-item" ><i className="fa-solid fa-eye me-2"></i>View </a></li> */}
                <li>
                  <a href="javascript:void(0)" onClick={() => openViewModal(row.doc_path, row.filetype)} className="dropdown-item">
                    <i className="fa-solid fa-eye me-2"></i>View
                  </a>
                </li>
                
                <li>
                  <button className="dropdown-item" onClick={() => fetchAndDownloadFromS3(row.doc_path_orginal,row.filemimetype, row.original_name) } >
                    <i className="fa-solid fa-download me-2"></i>Download File
                  </button>
                </li>
                {/* <li><a href="javascript:void(0)" className="dropdown-item reject"  onClick={()=>downloadFile(row.doc_path,row.original_name)}><i className="fa-regular fa-trash-can me-2"></i>Delete</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [showView, setShowView] = useState(false);
  const [KycView, setkycView] = useState({});

  const openViewModal = (path, type) => {
    setkycView({ url: path, type: type });
    setShowView(true);
  };

  const closeViewModal = () => {
    setkycView({});
    setShowView(false);
  };

  return (
    <>
      <div
        class="tab-pane fade business show active"
        id="business-tab-pane"
        role="tabpanel"
        aria-labelledby="business-tab"
        tabindex="0"
      >
        <div class="row d-flex align-items-center justify-content-center flex-wrap ">
          <div class="col-12 col-lg-6 mb-3 text-center text-lg-start">
            <h4 class="mt-3">KYC</h4>
            <p class="mb-0 tab-hd-p">
              Documents uploaded by the agent while creating the profile is
              listed here.
            </p>
          </div>
          <div class="col-lg-6 mb-3">
            <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
              {filters?.map((filter, index) => (
                <div className="sort-sec my-2 me-2" key={index}>
                  <p className="me-1">{filter.title} : </p>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) =>
                      handleFilterChange(filter.type, e.target.value)
                    }
                  >
                    {filter.options?.map((option) => (
                      <option value={option.value}>{option.option}</option>
                    ))}
                  </select>
                </div>
              ))}

              <button
                type="button"
                className="btn btn-primary my-2"
                onClick={handleShow}
              >
                Add Documents
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <DataTableList
            pageChange={onPageChange}
            totalCount={totalCount}
            columns={columns}
            tableData={listData}
            type={"documents"}
            isTableLoaded={isTableLoaded}
          />
        </div>
        {show && (
          <AddDocument {...{ show, handleClose, agentKey, fetchData }} />
        )}
        <Modal
          show={showView}
          // onHide={handleCloseEdit}
          dialogClassName="modal-dialog modal-dialog-centered modal-lg gallery-modal"
          centered
          id="agent-modal"
        >
          <div className="modal-content pt-2">
            <div className="modal-body">
              {KycView.type === "image" ? (
                <img src={KycView.url} alt="document" className="img-fluid" />
              ) : (
                <object
                  data={KycView.url}
                  type="application/pdf"
                  width="100%"
                  height="500px"
                >
                  <p>click here<a href={KycView.url}>to the PDF!</a></p>
                </object>
              )}
            </div>
            <a href="javascript:void(0)" className="modal-close">
              <i class="fa-solid fa-xmark" onClick={closeViewModal}></i>
            </a>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default KycList;

import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import InputField from "../../Components/Inputs/InputField";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { FormProvider, useForm } from "react-hook-form";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import Swal from "sweetalert2";
import AddImageField from "../../Components/Inputs/AddImageField";

function AddService({ show, handleClose, businessKey, fetchData,serviceData }) {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(true);

  const methods = useForm();

  const { handleSubmit, reset, register,trigger, setValue,resetField } = methods;

  // const FileUpload = async (url,img) => {
  //   console.log("file");
  //   const formData = new FormData();
  //   formData.append("image", img);
  //   try {
  //     // console.log(formData);
  //     const response = await MakeFileUpload(
  //       "post",
  //       formData,
  //       url
  //     );

  //     // console.log(response);
  //     // }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  const onSubmit = (data) => {
    if (serviceData) {
      update(data);
    } else {
      create(data);
    }
  };

  const create = async (data) => {
    // console.log(data);
    setSubmitDisabled(true);
    setShowCancelBtn(false)
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data, hasImage,fileKey,fileCordinates },
        `/business/${businessKey}/service/store`
      );
      // console.log(response);
      if (response.status == 1) {
        // image && (await FileUpload(`service/${response.servicedets.service_uuid}/uploadimage`,image));
        reset();
        handleClose();
        fetchData(1);
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const update = async (data) => {
    // console.log(data);
    setSubmitDisabled(true);
    setShowCancelBtn(false)
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data, hasImage,fileKey,fileCordinates },
        `/business/${businessKey}/service/${serviceData.service_uuid}/update`
      );
      // console.log(response);
      if (response.status == 1) {
        reset();
        handleClose();
        fetchData(1);
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  useEffect(() => {
    setSubmitDisabled(false);
    setShowCancelBtn(true)
    setImage(null);
    setImgPath(serviceData?.image || null);
    sethasImage(!!serviceData?.image);
    setImageModalShow(false);
    reset();
    resetField('title');
    reset({
      title: serviceData?.title || "",
    });
    return () => {
      setSubmitDisabled(false);
      setShowCancelBtn(true)
      setImage(null);
      setImgPath(null);
      sethasImage(0);
      setImageModalShow(false);
      reset();
      resetField('title');
    };
  }, [show]);

  const [imageModalShow, setImageModalShow] = useState(false);
  const [image, setImage] = useState();
  const [imgPath, setImgPath] = useState();
  const [hasImage, sethasImage] = useState(0);
  const [fileKey, setFileKey] = useState('');
  const [fileCordinates, setFileCordinates] = useState();

  const addImageRef = useRef(null);
  const openFile = () => {
    addImageRef.current.click();
  };

  function handleImgUpload(e) {
    if (e.target && e.target.files[0]) {
      sethasImage(1);
      setImage(e.target.files[0]);
      setImageModalShow(true);
      setSubmitDisabled(true);
    }
  }

  const croppedImage = (imgPath,tempKey,cordinates) => {
    setImage(null);
    setImgPath(imgPath);
    setImageModalShow(false);
    setSubmitDisabled(false);
    setFileKey(tempKey);
    setFileCordinates(cordinates || null);
    trigger("image");
  };

  function removeImg() {
    setImage(null);
    setImgPath(null);
    sethasImage(0);
  }

  
  const fields = [
    {
      name: "image",
      type: "addImage",
      title: "Add your service image",
      subtitle: "Recommended Size :1080px x 1080px",
      rules: {
        validate: {
          validateImg: async () => {
            // return (hasImage == 0 || hasImage == undefined) && "Please upload service image";
            var validationmessage = (hasImage == 0 || hasImage == undefined) ? (`Please upload service image`) : true;
            return  validationmessage;
          },
        },
      },
      hasImage: hasImage,
      hideRemovebtn: !showCancelBtn,
      imgRef: addImageRef,
      openFile: openFile,
      upload: handleImgUpload,
      remove: removeImg,
      imagePath: imgPath,
      showAddIcon: false,
      isShow: true,
    },
    {
      name: "title",
      placeholder: "Service Title",
      rules: { required: "Please enter service title" },
      type: "inputfield",
      isShow: true,
    },
  ];
const handleCloseCustom = ()=>{
  showCancelBtn && handleClose();
}
  return (
    <>
      <Modal
        show={show}
        // onHide={handleCloseCustom}
        dialogClassName="modal-dialog modal-dialog-centered modal-md"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="text-center">
                    <h5>{serviceData ? `Edit` : `Add New`} Services</h5>
                    <p className="gray">{serviceData ? `Edit service image and title` : `You can add services here`}</p>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
              {imageModalShow ? (
                <ImageCropper
                  file={image}
                  onCropped={croppedImage}
                  aspectRatio={1/1}
                />
              ):(<>
                {fields?.map((field, index) => {
                  return (
                    field.isShow && (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (field.type) {
                            case "inputfield":
                              return (
                                <InputField
                                  key={index}
                                  className="col-md-12 mb-3"
                                  {...field}
                                />
                              );
                            case "addImage":
                              return (
                                <AddImageField
                                  key={index}
                                  className="col-md-12 mb-3"
                                  {...field}
                                />
                              );
                            default:
                              break;
                          }
                        })()}
                      </React.Fragment>
                    )
                  );
                })}
</>)}
              </div>
            </div>
            <div className="modal-footer b-0">
            {showCancelBtn && <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleClose}
              >
                Cancel
              </button>
              }
                
              <button type="submit" className="btn btn-primary" disabled={isSubmitDisabled}>
                {serviceData ? `Update` : `Add`}
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}

export default AddService;

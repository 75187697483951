import React, {useState , useEffect} from 'react';   
import { useNavigate, useParams } from 'react-router-dom';
import { MakeAxiosRequest } from '../../utils/handler';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import dummyProfile  from "../../Assets/Images/dummy-profile.jpg"
import dummyBanner  from "../../Assets/Images/cover.png"
import CreateEditModal from './CreateEditModal';
import loaderFile   from '../../Assets/Images/loader.gif';
import { format } from 'date-fns';
import { logout,update } from '../../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Following from './Following';
import Subscription from './Subscriptions';
import Categories from './Categories';
import Offers from './Offers';
import Swal from 'sweetalert2';
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import Reviews from './Reviews';
import Reports from './Reports';
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";

function UserDetails() {

  const statusOptions = [
    { value: "0", className: "btn btn-success-outline m-1", icon:<i class="fa-solid fa-check me-2"></i>,  label: "activate" },
    { value: "1", className: "btn btn-danger-outline m-1", icon:<i class="fa-solid fa-trash-can me-2"></i>,  label: "deactivate" },    
  ];

    const dispatch = useDispatch()
    const authUser = useSelector((state) => state.auth.user);
    const  loginedKey =  authUser.user_uuid;
    const navigate = useNavigate();

    const params = useParams()
    const userKey = params.key;
    const tab = params.tab || 'following';



    const [userDetails, setuserDetails] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [subData, setServiceData] = useState([]);
    const [catData, setCategoryData] = useState([]);
    const [offerData, setOffersData] = useState([]);
    const [reviewData, setReviewsData] = useState([]);
    const [reportsData, setReportsData] = useState([]);
    const [followingData, setFollowingData] = useState([]);
    const { setBreadcrumbsData } = useBreadcrumb();
    const [TabType, setTabType] = useState('subscriptions'); 
    const [showEdit, setshowEdit] = useState(false);
    const [listParams, setListParams] = useState({
      offset: "0",
      // sortBy: "newest",
    });

   const [activeTab, setActiveTab] = useState(0);
   const onTabClick = (e, index) => {  
    // alert(index)
    changeTabList(tabList[index].value);
    setActiveTab(index);
  };
    // Breadcrumbs
      const updateBreadcrumbs = () => {
        setBreadcrumbsData([        
       
          { label: "User", path: `/users` },
          { label: "Details" },
        ]);
      };  
      useEffect(() => {
        updateBreadcrumbs();
      }, [showEdit])


     const userData = async () => {      
     const res = await MakeAxiosRequest("post", {}, `/user/details/${userKey}`);  
     setuserDetails(res.data.userDetails);  
     console.log(res.data.userDetails) ; 
     setIsLoaded(true);
     setTabList(res.data.tabs);
     const index = res.data.tabs.findIndex((item) => item.value === tab);
      if(index>0){
        changeTabList(tab);
        setActiveTab(index);
      }else{
        changeTabList('following');
      }
    // setTabType(res.data.selected_page);

    //  changeTabList(res.data.selected_page); 
  
     if(loginedKey === res.data.user_uuid){
      dispatch(update(res.data.userDetails));   
     }
    
    

    }
   

    useEffect(() => {
      userData();
      // setActiveTab(0);
     }, []);
 
    const handlePageChange = (page,newOffset) => {       
      changeTabList(page, { ...listParams, offset: newOffset.toString() });
     }
    const handleSelectChange = (page,filterName,value,newOffset) => {    
      changeTabList(page, { ...listParams, [filterName]: value,offset: newOffset.toString() });     
    };
    
    const changeTabList = async (page,listParams) => {
     // setIsLoaded(false);
     navigate('/user/'+userKey+'/'+page, { replace: true });

      const subres = await MakeAxiosRequest("post", {...listParams}, `/user/${userKey}/${page}`);     
    
      
      if (subres.data.userSubscriptions) {
        setServiceData(subres.data);
        // setIsLoaded(true);
      }
      if (subres.data.userCategories) {
        setCategoryData(subres.data);
      }
      if (subres.data.userOffers) {
        setOffersData(subres.data);
      }

      if (subres.data.reports) {
        setReportsData(subres.data);
      }

      if (subres.data.following) {
        setFollowingData(subres.data);
      }

      if (subres.data.userReviews) {
        setReviewsData(subres.data);
      }
     
     
      
      

      $(`tab-pane`).removeClass("show active");
      $(`${page}-tab-pane`).addClass("show active");
       
    };
   
    
  const [isEdit, setisEdit] = useState(false);

  

  

 

  const handleshowEdit = (user) => {
    setisEdit(true)
    setshowEdit(true)
    setuserDetails(user)
    
  };
  const handleCloseEdit = () => {
    setshowEdit(false);
    setisEdit(false)
    
  }

  const renderTextWithLineBreaks = (data) => {
    const html = data.replace(/\n/g, '<br />');
    return {__html:html}  ;
  };


   
     // Status change of users 
     const changeStatus = async (userKey, status, type) => {
      try {
        const statusType = type.toLowerCase();    
        let showConfirmButton = true; 
        const result = await Swal.fire({
          title: 'Are you sure?',
          html: `Are you sure you want to ${status} this <br>${statusType}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, ' + status,
          customClass: {  confirmButton: 'btn btn-primary'  },
          cancelButtonText: 'Cancel',
         // showLoaderOnConfirm: true,
          reverseButtons: true,  
          preConfirm: async () => {
            try {
              Swal.update({
                showConfirmButton: showConfirmButton
              });    
              const res = await MakeAxiosRequest(
                'post', {"status":status}, `user/${userKey}/changestatus`
              );         
              if (res.status === 1) {
                return true; 
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error(error); // Log the error
              Swal.showValidationMessage(`Request failed: ${error.message}`);
            }
          },
        
        });
        if (result.isConfirmed) {
          if (result.value === true) {
            $('#tranfer-account').modal('hide');
            Swal.fire({
              icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, text: type + ' ' + status + 'd successfully.'
            });
            userData();
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',
        });
      }
    };

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <center><img src={loaderFile} className="loader" style={{ width: '125px' }} alt="Loading..." /></center> ;
    } else {  

  return (
    <>
      <div className="page">
      {/* Content Row */}
        <div className="row align-items-center">
          <div className="col-sm-10 col-lg-7">
          <div className="d-flex align-items-center mb-3">
          <a href='javascript:void(0)' onClick={() => navigate(-1)} className="back-btn">
              <i class="fa-solid fa-circle-arrow-left me-1 me-sm-3"></i>
            </a>
            <Breadcrumbs />
          </div>
          </div>
        </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-xl-8 col-xxl-3 mx-auto mb-3">
                      <div className="card cmpny-dtls">
                        <div className="cmpny-cover position-relative mb-4">
                           <img className="cmpny-cover-img" src={dummyBanner} alt="" />
                        
                          <div className="company-prfl">
                          <img className="" src={userDetails.profile_img || dummyProfile} />
                          </div>
                        </div>
                        <div className="card-body mt-5">
                          <div className="text-center">
                            <h1 className="mb-0">{userDetails.first_name} {userDetails.last_name}</h1>
                            {userDetails.created_at && (
                                 <p class="join-dt mb-0"> <span>Joined on:</span> {userDetails.created_at} </p>
                            )}
                            <span className="agent-type mb-2">{userDetails.user_role}</span>
                            <p className="mb-2">{userDetails.email}</p>
                          
                           
                            {userDetails.updated_at && (
                                 <p class="join-dt"> <span>Updated on:</span> {userDetails.updated_at} </p>
                            )}
                          </div>
                          <div class="btn-group d-flex justify-content-center align-items-center">
                       
                            <a href="javascript:void(0)" class="btn btn-primary m-1" onClick={() => handleshowEdit(userDetails)}>
                              <i class="fa-solid fa-pen me-2"></i> Edit </a>
                              {loginedKey != userDetails.user_uuid && statusOptions.map(option => {
                                const statusOpt = option.label.charAt(0).toUpperCase() + option.label.slice(1);
                                return userDetails.status === option.value && (                               
                                  <a href="javascript:void(0)" className={option.className} onClick={() => changeStatus(userDetails.user_uuid, option.label, userDetails.user_role)}>
                                    {option.icon} {statusOpt}
                                  </a>
                                );
                              })}

                             
                             
                          </div>
                        
                           
                          <div className="cmpny-dtls-txt mt-4">  
                            {userDetails.phone_number && (     
                               <>                     
                                <span className="d-block mb-2">Phone Number</span>
                                <p>+91 {userDetails.phone_number}</p> 
                              </>
                            )}     
                                                     
                            {userDetails.address && (
                                <>
                                  <span className="d-block mb-2">Address</span>
                                  <p className='' dangerouslySetInnerHTML={renderTextWithLineBreaks(userDetails.address)}/>
                                 
                               </>
                            )}                           
                           {userDetails.pincode && (
                                <>
                                  <span className="d-block mb-2">Pin Code</span>
                                  <p>{userDetails.pincode}</p>
                               </>
                            )}   
                             {userDetails.location && (
                                <>
                                  <span className="d-block mb-2">Location</span>
                                  <p>{userDetails.location}</p>
                               </>
                            )}                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-12 col-xxl-9 mx-auto mb-3">
                      <div className="cmpny-dtls-tabs">

                     
                      <Tabs
                      activeTab={activeTab}
                      onTabClick={onTabClick}                    
                      navBtnCLickAnimationDuration={1000}
                      // tabsScrollAmount={3}
                      hideNavBtnsOnMobile	={false}
                      animationDuration={1000}
                      hideNavBtns={true}
                      tabsContainerClassName="scrtabs-tabs-fixed-container"
                      tabsUpperContainerClassName="scrtabs-tab-container"                    
                      navBtnAs="div"
                      navBtnClassName="navBtnClassName"
                      navBtnContainerClassName="navBtnContainerClassName"
                    >
                  
                             {tabList.map((option, optionIndex) => (
                              <Tab key={optionIndex} className="tabbbb" tabAs="div">
                                <button className={`nav-link ${option.value}-tab`} id={`#${option.value}-tab`}  data-bs-toggle="tab" data-bs-target={`#${option.value}-tab-pane`} type="button" role="tab"  aria-controls={`${option.value}-tab-pane`}  aria-selected="false"> {option.option}</button>
                                </Tab>
                            
                            ))}
                        
                         </Tabs>
                        
                 





                    
                      


                            {/* Tab section */}
                              <div className="tab-content" id="myTabContent">
                              {tabList.map((option, optionIndex) => (
                                  <TabScreen key={option.option}
                                  activeTab={activeTab}
                                  index={optionIndex}  className="some-animation-class"  >
                                    {catData && option.option === "Categories" && (
                                      <Categories catData={catData} handlePageChange={handlePageChange} />
                                    )}

                                    {subData && option.option === "Subscriptions" && (
                                      <Subscription subData={subData} handlePageChange={handlePageChange} />
                                    )}

                                    {offerData && option.option === "Offers" && (
                                      <Offers offerData={offerData} handlePageChange={handlePageChange} handleSelectChange ={handleSelectChange} />
                                    )}

                                    {reviewData && option.option === "Reviews" && (
                                      <Reviews reviewData={reviewData} handlePageChange={handlePageChange} handleSelectChange ={handleSelectChange}  />
                                    )}

                                    {reportsData && option.option === "Reported Issues" && (
                                      <Reports reportsData={reportsData} handlePageChange={handlePageChange} />
                                    )}

                                    {followingData && option.option === "Following" && (
                                      <Following followingData={followingData} handlePageChange={handlePageChange} />
                                    )}
                                  </TabScreen>
                                ))}
                              </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <CreateEditModal {...{ showEdit, handleCloseEdit,isEdit, userDetails,userData }} />

    </>
  )
}
}

export default UserDetails
import React, {useState, useEffect} from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { MakeAxiosRequest } from '../../utils/handler';
import loaderFile   from '../../Assets/Images/loader.gif';
import { format } from 'date-fns';
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import Swal from 'sweetalert2';


function Listing({searchValue}) {

  const navigate = useNavigate();
  const params = useParams();
  const sortby = params.sortby || "newest";
  const status = params.status || "all";

  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "Inactive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
    { value: "3", className: "status-tag status-tag-inactive", label: "Expired" },
  ];

  

const columns = [
 
  {
    name: <div className="datatable-hd"><h6>Name</h6></div>,
    selector: row => (
      <UserRowActions  row={{ primaryData: row.title, imagePath :row.profile_img, sublistData: row.type,
        viewUrl: `/business/${row.business_uuid}` 
      }}/>  
    ),
  },
  {
    name: <div className="datatable-hd"><h6>Email/Phone Number</h6></div>,
    selector: row => (
      <UserRowActions row={{ primaryData: row.email, sublistData: row.contact_number, class:'disable-tag-click',   }} />    
    ),
  },
   { name: <div className="datatable-hd"><h6>Agents</h6></div>,
    selector: row => ( <UserRowActions  row={{ primaryData: row.agent_user, class:'disable-tag-click', }} /> ),
   },
   {
    name: <div className="datatable-hd"><h6>Created By</h6></div>,
     selector: row => (
       <UserRowActions  row={{ primaryData: row.created_by, class:'disable-tag-click',     
        sublistData: format(new Date(row.created_at),  'dd/MM/y'), 
       }} />     
     ),
   },
  {   
    name: <div className="datatable-hd"><h6>Updated By</h6></div>,
    selector: row => (  <UserRowActions  row={{ primaryData:  row.updated_by , 
      sublistData: row.updated_by !== '' &&  row.updated_at !=null? format(new Date(row.updated_at),  'dd/MM/y') :  "--" , 
      class:'disable-tag-click',  }}/>    
      ),     
  },
  {
    name: <div className="datatable-hd"><h6>Status</h6></div>,
    selector: row => (
      <div data-title="" className="numeric data-table-sec">
        {statusOptions.map(option => {
          return row.status === option.value && (
            <span key={option.value} className={option.className}>{option.label}</span>
          );
        })}
     </div>
    ),
  },
  {
    selector: row => (
      <UserRowActions row={{
            tableData: row,
            actionkey: row.business_uuid,
            actionrole: row.user_role || '',
            actionstatus: row.status,
            isModal:'0',
            type:'action',
            viewUrl:`/business/${row.business_uuid}`,
            editUrl:`/businesses/edit/${row.business_uuid}`
        }}     
      changeStatus={changeStatus} />
     
    ),
  },
    
];



    // Status change of businesses 
    const changeStatus = async (businessKey, status) => {
      try {
      
        let showConfirmButton = true; 
        const result = await Swal.fire({
          title: 'Are you sure?',
          html: 'Are you sure you want to '+status+'  this business?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, ' + status,
          customClass: {  confirmButton: 'btn btn-primary'  },
          cancelButtonText: 'Cancel',
        //  showLoaderOnConfirm: true,
          reverseButtons: true,  
          preConfirm: async () => {
            try {
              Swal.update({
                showConfirmButton: showConfirmButton
              });    
              const res = await MakeAxiosRequest(
                'post', {"status":status}, `business/${businessKey}/changestatus`
              );        
              if (res.status === 1) {
                return true; 
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
               Swal.showValidationMessage(`Request failed: ${error.message}`);
            }
          },         
        });
        if (result.isConfirmed) {
          if (result.value === true) {
            $('#tranfer-account').modal('hide');
            Swal.fire({ icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, 
             text: 'Business ' + status + 'd successfully.'
            });
            fetchData(1, perPage);
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',
        });
      }
    };
  


  const [isTableLoaded, setisTableLoaded] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [filters, setFilters] = useState([]);
  const [listingData, setlistingData] = useState([]);
  const { setBreadcrumbsData } = useBreadcrumb();
  const [listParams, setListParams] = useState({
    offset: "0",
    status: status,
    sortBy: sortby,
  });


   // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData([     
        { label: "Businesses" },
        { label: "Listing" },
      ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 

  const fetchData = async (page, per_page) => {      
      const res = await MakeAxiosRequest("post", {...listParams,searchValue}, "/business");
      setIsLoaded(true);
      setItems(res.data.business);
      const statusCounts = {
        all: res.data.totalcount,
        active: res.data.activecount,
        inactive: res.data.inactivecount,
        pending: res.data.pendingcount,
      };
      setisTableLoaded(true);
      const selectedStatus = listParams.status || 'active'; // Default to 'active' if status is not provided      
      setTotalRows(statusCounts[selectedStatus]);
      setFilters(res.data.filters); 
      
  }
  useEffect(() => {
    fetchData(1, perPage);
  }, [listParams,searchValue,perPage]);


  const handlePageChange = page => {
    const newOffset = (page - 1) ;
    setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));   
  }

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   setPerPage(newPerPage);
  // }
  const handleSelectChange = (filterName,value) => {  
    setisTableLoaded(false);  
    console.log(filterName);
    console.log(value);
    if(filterName==='sortBy')
      navigate('/businesses/'+value+'/'+listParams.status);  
    if(filterName==='status')
      navigate('/businesses/'+listParams.sortBy+'/'+value);  
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };

 

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
    <div className="row d-flex align-items-center flex-wrap ">
        <div className="col-12">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap">
          {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option => 
                  <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                )}                        
              </select>
            </div>
          ))}      
            <Link to="/businesses/create" className='btn btn-primary my-2' >Create</Link>            
          </div>
        </div>
      </div>
      <div className="">          
          <DataTableList  pageChange={handlePageChange}   totalCount={totalRows}  columns={columns}  tableData={items}  type ={'businesses'} isTableLoaded={isTableLoaded} />    
      </div>     
      </>
    );
  }
}

export default Listing;

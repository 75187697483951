import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
const UserRowActions = ({ row, handleshowEdit, changeStatus, makeSuperAgent }) => {
const dispatch = useDispatch()
const authUser = useSelector((state) => state.auth.user);
const  loginedKey =  authUser.user_uuid;

  return (
  <>
  
    {row.type === 'action' ? (
      <div data-title="" className="numeric data-table-sec">
        <div className="d-flex justify-content-end">
          <div className="btn-group option-btn">
            <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
              <i className="fa-solid fa-ellipsis"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              {row.isModal === '1' ? (
                <li><a href="javascript:void(0)" onClick={() => handleshowEdit(row.tableData)} className="dropdown-item danger"><i className="fa-solid fa-pen me-2"></i>Edit</a></li>
              ) : (
                <li><Link to={row.editUrl} className="dropdown-item danger"><i className="fa-solid fa-pen me-2"></i>Edit</Link></li>
              )}

              {(() => {
                switch (row.actionstatus) {
                  case "0":
                    return (
                      <>
                        <li><a href="javascript:void(0)" className="dropdown-item success" onClick={() => changeStatus(row.actionkey, 'activate', row.actionrole)}><i className="fa-solid  fa-square-check me-2"></i>Activate</a></li>
                        <li><Link to={`/agent/${row.actionkey}`} className="dropdown-item"><i className="fa-solid fa-eye me-2"></i>View</Link></li>
                      </>
                    );
                  case "1":
                    return (
                      <>
                       { loginedKey != row.actionkey && (
                        <li><a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(row.actionkey, 'deactivate', row.actionrole)}><i className="fa-regular fa-trash-can me-2"></i>Deactivate</a></li>

                        )}
                        <li><Link to={row.viewUrl} className="dropdown-item"><i className="fa-solid fa-eye me-2"></i>View</Link></li>
                        {row.actionrole === 5 && (
                          <li>
                            <a href="javascript:void(0)" className="dropdown-item" onClick={() => makeSuperAgent(row.actionkey)}>
                              <i className="fa-solid fa-shield-halved me-2"></i> Promote to Super Agent
                            </a>
                          </li>
                        )}
                      </>
                    );
                  case "2":
                    return (
                      <li><a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(row.actionkey, 'delete', row.actionrole)}><i className="fa-regular fa-trash-can me-2"></i>Delete</a></li>
                    );
                  default:
                    return null;
                }
              })()}
            </ul>
          </div>
        </div>
      </div>
    ) : (
      
      
        <div data-title="" className="numeric data-table-sec">

          <Link to={row.viewUrl} className={row.class || ''}> 
           {row.imagePath && row.imagePath !='' ?(
            <div className="profile-sec">
              <img src={row.imagePath} alt="" />
              <div>
                <p>{`${row.primaryData} ${row.secondryData ? row.secondryData : ''}`}</p>
                {row.sublistData && <span className="agent-type">{row.sublistData}</span>}
              </div>
            </div>
            ):(
              <>
              {row.primaryData && <p>{`${row.primaryData} `}</p>}
              {row.sublistData && <span className="agent-type">{row.sublistData}</span>}
              </>
            )
           }
            

         </Link>

       </div>
       
      
    )}
  </>
  );
};

export default UserRowActions;

import React from 'react'
import { Link } from "react-router-dom";
import StarRating from "../../Components/Listing/StarRating";
import { Modal } from 'react-bootstrap';
import { format } from "date-fns";

function ViewModal(
    {showView,
    handleCloseView,
    Review}
) {
console.log(showView);
    const statusOptions = [
        { value: "-1", className: "status-tag status-tag-pending", label: "Pending" },
        { value: "2", className: "status-tag status-tag-inactive", label: "Rejected" },
        { value: "1", className: "status-tag status-tag-active", label: "Approved" },
      ];
     

    return (
        <>
            <Modal
                show={showView}
                // onHide={handleCloseView}
                dialogClassName="modal-dialog modal-dialog-centered modal-lg"
            >

                {Review && <><div className="modal-body review-popup pb-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <h5>Review</h5>
                                        </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row mt-5">
                                    <div className="col-md-6 mb-4">
                                        <h6>Name</h6>
                                        <Link to={Review?.user_status !== '2' ? `/user/${Review?.user_uuid}` : "javascript:void(0)"}>
                                        <div className="profile-sec d-flex align-items-center">
                                            <img src={Review.profile_img} alt="" />
                                            <div className='text-start'>
                                                <p className='mb-0'>{Review.first_name && Review.first_name} {Review.last_name && Review.last_name}</p>
                                                <span className='date'>{format(new Date(Review.created_at), 'dd/MM/y')}</span>
                                            </div>
                                        </div>
                                    </Link>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <h6>Business</h6>
                                        <div>
                                            <Link to={`/business/${Review.business_uuid}`}>
                                            <div className="profile-sec d-flex align-items-center">
                                                    <img src={Review.business_img} alt="" />
                                                    <div>
                                                        <p className='mb-0'>{Review.title && Review.title}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <h6>Rating</h6>
                                        <div className='review-rating'>
                                            {Review.rating && <StarRating count={Review.rating} />}
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <h6>Review</h6>
                                        <p className='mb-0'>{Review.comment}</p>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <h6>Status</h6>
                                        {statusOptions.map(option => {
                                            return Review.status === option.value && (
                                                <span key={option.value} className={option.className}>{option.label}</span>
                                            );
                                        })}
                                    </div>
                                  {(Review.updated_by != '') && <div className="col-md-6 mb-4">
                                        <h6>Approved / Rejected By</h6>
                                        <Link to={`/user/${Review.updated_by_uuid}`}>
                                            <div className="profile-sec d-flex align-items-center">
                                                <img src={Review.updated_by_image} alt="" />
                                                <p className='mb-0'>{Review.updated_by && Review.updated_by}</p>
                                            </div>
                                        </Link>
                                    </div>}
                                </div>
                           

                </div>

                <div className="modal-footer b-0">
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleCloseView}
                    >
                        Close
                    </button>
                </div>
                </> }

            </Modal>
        </>
    )
}

export default ViewModal
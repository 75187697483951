import React, {useState , useEffect} from 'react'; 
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import businessimg from '../../Assets/Images/business-img.png';
import aladinzImage from '../../Assets/Images/logo-aladdin.png';
const ListingComponent = ({ transactionData,handlePageChange,handleSelectChange,role }) => {

  
  //const filters = agentlistData?.filters || [];
  const totalCount = transactionData?.totalcount;
  const transactions = transactionData?.transactions || [];
  const account_balance = transactionData?.account_balance;
  const last_updated_at = transactionData?.last_updated_at || [];
  const filters = transactionData?.filters || [];
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });

  const [isTableLoaded, setisTableLoaded] = useState(true);
  const handleFilterChange = (type, value) => {
    handleSelectChange('transactions',type,value,currentPage);   
    setFilterData({ type, value });
  };


   const agentData =() =>{    
    setisTableLoaded(account_balance !== undefined);       
   };

  useEffect(() => {
   
    agentData()
  }, [account_balance]);

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('transactions',newOffset,type,value);   
    setCurrentPage(newOffset);
  };



  const columns = [
    {
    
      name: <div className="datatable-hd"><h6>Name</h6></div>,
      selector: row => ( <div data-title="" className="numeric data-table-sec" > <Link className='d-flex align-items-center' ><div className='profile-sec'><img src={row.business === 'Aladinz' ? aladinzImage : (row.businessImage ? row.businessImage : businessimg)} />
      </div><div><p>{row.business} </p> </div></Link>  </div> ),
  
  },
  {
    name: <div className="datatable-hd"><h6>Paid To</h6></div>,
    selector: row => ( <div data-title="" className="numeric data-table-sec" > <div><p>{row.paid_to || '-'} </p> </div>  </div> ),
    omit:  (role === 'Agent' ? true : false ),
  },
  
  {
    name: <div className="datatable-hd"><h6>Transaction Type</h6></div>,
    selector: row => ( <div data-title="" className="numeric data-table-sec" > <div><p>{row.transaction_type} </p> </div>  </div> ),
  },
    {
      name: <div className="datatable-hd"><h6>Transaction Date</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
        <p>{format(new Date(row.created_at), 'dd/MM/y')}</p>
      
      </div>),   
  },
  {
    name: <div className="datatable-hd"><h6>Description</h6></div>,
    selector: row => (
      <div data-title="" className="numeric data-table-sec">
      <p>{row.description ? row.description : '--'}</p>
    
    </div>),   
},
  {
    
    name: <div className="datatable-hd"><h6>Amount</h6></div>,
    selector: row => ( <div data-title="" className="numeric data-table-sec" >
       <div>
        <p className={row.payout_type_id === 3 ? 'debit-amt' : 'credit-amt'}>
          {row.payout_type_id === 3 ? <span><i class="fa-solid fa-minus"></i></span> : <span><i class="fa-solid fa-plus"></i></span>} <i className="fa-solid fa-indian-rupee-sign"></i> {row.amount}
         </p>
        </div>  </div> ),

},
 
    
    
  
      
  ];

  return (
    <>
     <div class="tab-pane fade show active" id="transactions-tab-pane" role="tabpanel" aria-labelledby="transactions-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
      <div class="col-lg-8 mb-3 text-center text-lg-start">
            <h4 class="mt-3">Transactions</h4> 
            <p class="mb-0">All the agent transactions (Subscriptions/ Renewals/ Payouts) will be listed in this section.</p>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            {filters?.map((filter, index) => (
                <div className="sort-sec my-2 me-2" key={index}>
                  <p className="me-1">{filter.title} : </p>
                  <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                    {filter.options?.map( option => 
                      <option value={option.value} >{option.option}</option>
                    )}                        
                  </select>
                </div>
              ))}
            
            </div>
          </div>
          {role=='Agent' &&
          <div class="col-12">
            <div class="acnt-bal-sec">
              <h5 class="my-2">Account Balance: <b>₹{account_balance || 0}</b></h5>
              {last_updated_at != '' && (
                <p class="my-2">Last updated on {format(new Date(last_updated_at), 'MM/dd/Y')}</p>
              )}

             
            </div>
          </div>
}
        </div>
        <div className="">         
          <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}  tableData={transactions}  type ={'transactions'}  isTableLoaded={isTableLoaded} />  
        </div>
      </div>
    </>
  );
};

export default ListingComponent;

import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function TextArea({ name, label, rules, className }) {
  const { control, formState: { errors }, setValue } = useFormContext();

  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">{label}</label>

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
            <CKEditor
            editor={ ClassicEditor }
            data={field.value} // Set initial value from form context
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setValue(name, data); // Update form value
            } }
            />
        )}
      />

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default TextArea;

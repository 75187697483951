import React, {useState , useEffect} from 'react';   
import { format } from 'date-fns';
import dummyBanner  from "../../Assets/Images/dummy.jpg"
import noRecords   from '../../Assets/Images/no-records.png'
import ListPagination from "../../Components/Listing/ListPagination";
import Swal from "sweetalert2";
import offerimg from '../../Assets/Images/offer.png';
import { MakeAxiosRequest } from "../../utils/handler";
import loaderFile   from '../../Assets/Images/loader.gif';
const ListingComponent = ({ offerData ,handlePageChange, handleSelectChange}) => {

  const userOffers = offerData?.userOffers || [];
  const totalCount = offerData?.totalcount;
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const [isLoaded, setIsLoaded] = useState(false);


  const statusOptions = [
    { value: "2", className: "offer-tag rejected-tag", label: "Rejected" },
    { value: "1", className: "offer-tag approved-tag", label: "Active" },
    { value: "-1", className: "offer-tag pending-tag", label: "Pending" },
  ];


  const fetchData = () => {
    setIsLoaded(totalCount !== undefined);    
  };
  
  useEffect(() => {
    fetchData();
  }, [totalCount]);



  const handleFilterChange = (type, value ) => {

    handleSelectChange('offers',type,value,currentPage);   
    setFilterData({ type, value });
    
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    const type = filterData.type;
    const value = filterData.value;
    handlePageChange("offers", newOffset, type, value);
    setCurrentPage(newOffset);
  };

  
   
    // Status change of offers 
    const changeStatus = async (offerKey, status) => {
      try {       
        const result = await Swal.fire({
          title: 'Are you sure?',
          html: 'Are you sure you want to ' + status + ' this category?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, ' + status,
          customClass: {  confirmButton: 'btn btn-primary'  },
          cancelButtonText: 'Cancel',
          //showLoaderOnConfirm: true,
          reverseButtons: true,  
          preConfirm: async () => {
            try {              
              const res = await MakeAxiosRequest( 'post', { "status": status }, `offer/${offerKey}/changestatus` );  
              if (res.status === 1) {
                return true; 
              } else {
                throw new Error(res.message);
              }
            } catch (error) {  
              Swal.fire({
                icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' },
                 text: `Request failed: ${error.message}`,
              });        
              // Swal.showValidationMessage(`Request failed: ${error.message}`);
            }
          },         
        });
        if (result.isConfirmed) {
          if (result.value === true) {
            $('#tranfer-account').modal('hide');
            Swal.fire({   icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' },
             text: 'offer ' + status + 'd successfully.' });
             handleFilterChange(filterData);
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',
        });
      }
    };
  


    
    const markPremium = async (offerKey) => {
      try {
        let showConfirmButton = true;
        const result = await Swal.fire({
          title: 'Are you sure?',
          html: 'Are you sure you want to upgrade this offer?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: { confirmButton: 'btn btn-primary' },
          cancelButtonText: 'Cancel',
         // showLoaderOnConfirm: true,
          reverseButtons: true,
          preConfirm: async () => {
            try {
              Swal.update({
                showConfirmButton: showConfirmButton
              });    
              const res = await MakeAxiosRequest('post', {}, `offer/${offerKey}/markaspremium`);    
              if (res.status === 1) {
                return true; 
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error(error); // Log the error
              Swal.showValidationMessage(`Request failed: ${error.message}`);
            }
          },
        
        });
    
        if (result.isConfirmed && result.value === true) {
          $('#tranfer-account').modal('hide');
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            customClass: { confirmButton: 'btn btn-primary' },
            text: 'Offer upgraded successfully.',
          });
          handleFilterChange(filterData);
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          customClass: { confirmButton: 'btn btn-primary' },
          text: 'An error occurred while changing the status.',
        });
      }
    };
    

  return (
    <>
        <div class="tab-pane fade show active" id="offers-tab-pane" role="tabpanel" aria-labelledby="followers-tab" tabindex="0">
                            <div class="row d-flex align-items-center justify-content-center flex-wrap ">
                              <div class="col-lg-8 mb-3 text-center text-lg-start">
                                <h4 class="mt-3">Offers</h4>
                                <p class="mb-0">You can view all the offers  you have reviewed to aladinz </p>
                              </div>
                              <div class="col-lg-4 mb-3">
                                <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
                                {offerData?.filters?.map((filter, index) => (
                                  <div className="sort-sec my-2 me-2" key={index}>
                                    <p className="me-1">{filter.title} : </p>
                                    <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                                      {filter.options?.map( option => 
                                        <option value={option.value} >{option.option}</option>
                                      )}                        
                                    </select>
                                  </div>
                                ))}
                                  
                                </div>
                              </div>
                            </div>
                           <div class="row">
                              <div class="col-12">
                              {!isLoaded ? (<div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>):(
                                 <div class="row">
                               
                                 {offerData?.userOffers?.length > 0 ? (
                                    <>
                                      {userOffers.map((offers, optionIndex) => (
                                        <div className="col-md-6 col-lg-4 col-xl-4 mb-3" key={optionIndex}>
                                        <div className="card offers-card">
                                          <div className="offers-card-hd position-relative">
                                            <img className="offer-banner" src={offers.banner_image_path ? offers.banner_image_path : dummyBanner} alt="" />
                                            <div className="offer-optn mt-3">
                                              {statusOptions.map(option => {
                                                return offers.status === option.value && (
                                                  <span key={option.value} className={option.className}>{option.label}</span>
                        
                                                );
                                              })}
                                              <div className="d-flex align-items-center">
                                                {offers.is_exclusive === "1" && (
                                                  <span className="offer-tag  premium-tag">
                                                    <i className="fa-regular fa-gem me-1"></i> Premium
                                                  </span>
                                                )}
                                                <div className="btn-group option-btn">
                                                  <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                                    <i className="fa-solid fa-ellipsis"></i>
                                                  </a>
                                                  <ul className="dropdown-menu dropdown-menu-end">
                                                
                                                    {(() => {
                                                      switch (offers.status) {
                                                        case "-1":
                                                          return (
                                                            <>
                                                            <li><a href='javascript:void(0)' className="dropdown-item success" onClick={() => changeStatus(offers.offer_uuid,'approve')}><i className="fa-solid  fa-square-check me-2"></i>Approve</a></li>
                                                            <li><a href='javascript:void(0)'  className="dropdown-item reject"  onClick={() => changeStatus(offers.offer_uuid,'reject')}><i className="fa-regular fa-trash-can me-2"></i>Reject</a></li>
                                                            </>
                                                          );
                                                        case "1":
                                                          return (
                                                            <>
                                                            {offers.is_exclusive != "1" && (
                                                              <li><a href="javascript:void(0)" className="dropdown-item" onClick={() => markPremium(offers.offer_uuid)}><i className="fa-regular fa-square-check me-2"></i> Mark as premium</a></li>
                                                              )}
                                                              <li><a href='javascript:void(0)'  className="dropdown-item reject"  onClick={() => changeStatus(offers.offer_uuid,'deactivate')}><i className="fa-regular fa-trash-can me-2"></i>Deactivate</a></li>
                                                            </>
                                                          );
                                                        case "2":
                                                          return (
                                                            <>
                                                              <li><a href='javascript:void(0)'  className="dropdown-item success" onClick={() => changeStatus(offers.offer_uuid,'activate')} ><i className="fa-solid  fa-square-check me-2"></i>Activate</a></li>
                                                            </>
                                                          );
                                                        default:
                                                          return null;
                                                      }  
                                                    })()}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body offer-dtls">
                                            <div className="mb-3">
                                              <span className='mb-0'>Offer Title</span>
                                              <p>{offers.title}</p>
                                            </div>
                                            <div className="mb-3">
                                              <span className='mb-0'>Offer Description</span>
                                              <p className=''>{offers.description}</p>
                                            </div>
                                            <div>
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <span className='mb-0'>Start date</span>
                                                  <p>{format(new Date(offers.start_date), 'MM/dd/Y')}</p>
                                                </div>
                                                <div className="col-md-6">
                                                  <span className='mb-0'>End date</span>
                                                  <p>{format(new Date(offers.end_date), 'MM/dd/Y')}</p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="offer-img-sec">
                                              <span className=''>Offer Image</span>
                                              <div className="img-sec">
                                                <img className="me-2" src={offers.card_image_path ? offers.card_image_path : offerimg}/>
                                                <p class="mb-0">{offers.card_img_name}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      ))}
                                       <ListPagination pageChange={handlePageClick} totalCount={offerData.totalcount}/>
                                    </>
                                  ) : (
                                    <>
                                      <div className='mt-4 text-center'>
                                        <img src={noRecords} style={{ width: '150px' }} alt="No records found" />
                                        <div className='mt-3'>There are no offers to display.</div>
                                      </div>
                                    </>
                                  )}



                                
                                   
                                    
                               
                                   
                                 </div>
                              )}
                              </div>
                           </div>
                          </div>
    </>
  );
};

export default ListingComponent;

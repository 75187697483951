import React, { useEffect,useState } from 'react'
import { MakeAxiosRequest } from '../../utils/handler';
import loaderFile   from '../../Assets/Images/loader.gif';
import ListPagination from "../../Components/Listing/ListPagination";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUnreadNotificationCount } from '../../features/authSlice';
import noRecords from '../../Assets/Images/no-records.png'

function notificationslist() {
    

    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [notifications, setNotification] = useState({});
    const [error, setError] = useState(null);
    const [count, setCount] = useState('');
    const [listParams, setListParams] = useState({
      offset: "",
      sortBy: "newest",
    });

    const handlePageClick = (event) => {
        setListParams(prevParams => ({ ...prevParams,'offset': event.selected, }));
    
      };

    const fetchNotifications = async () => {
        try {
    
          const res = await MakeAxiosRequest("post",{'key':'all' ,...listParams}, '/fetchnotifications');
          setIsLoaded(true);
          setNotification(res.notification)
          setCount(res.totalcount)
        } catch (error) {
          console.error('Error fetching notification:', error);
        }
      };

      const markAsRead = async (notificationKey) => {
        try {
    
          const res = await MakeAxiosRequest("post", {'key' : notificationKey }, `/notifications/${notificationKey}/markasread`);
           if(res.success == '1'){
              // window.location.href = res.url;
              navigate(res.url);
              const result = await MakeAxiosRequest("post", {}, `/notifications/unreadcount`);
              dispatch(updateUnreadNotificationCount(result)); 
           }
        } catch (error) {
          console.error('Error fetching notification:', error);
        }
      };

    useEffect(() => {     
        fetchNotifications();     
     }, []);
     useEffect(() => {     
      fetchNotifications();     
   }, [ listParams]);
     
    if (error) {
    return <div>Error: {error.message}</div>;
    }  else{
        return (
        <> 
        <div className="page">
          <div className="row">
            <div className="col-12">
              <div className="card mb-3">
                <div className="card-body">
                {isLoaded ? (<ul className='notification-list'>
                  {notifications.length > 0 ?
        notifications.map((notification, optionIndex) => (
        
        <li className={`notfctn-lst px-3 py-2 ${notification.is_read === '0' ? 'notf-unread' : 'notf-read'}`} key={optionIndex}>
        <a href='javascript:void(0)' className="d-flex align-items-center justify-content-between" onClick={() => markAsRead(`${notification.notification_key}`)}>
            <div className="d-flex">
              <div>
              <img className="rounded-circle d-block" src={notification.image} />
              </div>
                
                <div>
                  <div className='d-flex align-items-center'>
                    <h6 className='mb-0'>{notification.name}</h6>
                    {notification.is_read =='0' && <span className="online-indctn ms-2 mb-0"></span>}
                    
                  </div>
                  <p className="time mb-0">{notification.time}</p>
                  <p>{notification.message}</p>
                  </div>
                </div>
                
            </a>
            </li>
            )

            ):(<div className="my-4 text-center">

            <img src={noRecords} style={{ width: '150px' }} alt="No records found" />
            
            <div className='mt-3'>There are no notifications to display.</div>
             
            
          </div>)}
                  </ul>):(
                      <div>
                      <center>
                        <img src={loaderFile} style={{ width: "125px" }} alt="Loading..." />
                      </center>
                    </div>
                  )}
                  
                
        <ListPagination pageChange={handlePageClick} totalCount={count}/>  
                </div>
              </div>
            </div>
          </div>
          </div> 
                 
        </>
        )
    }
}

export default notificationslist
import React, {useState, useEffect} from 'react';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import AddReview from "./AddReview";
import NoDataFound from "../../Components/Listing/NoDataFound";
import ShowMoreModal from "../../Components/Listing/ShowMoreModal";
import StarRating from "../../Components/Listing/StarRating";

function Reviews({ reviewsData, handlePageChange, handleSelectChange }) {
  const params = useParams();
  const businessKey = params.key;

  const totalCount = reviewsData?.totalcount;
  const columns = [
    {
      name: (
        <div className="datatable-hd">
          <h6>Name</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <div className="profile-sec">
            <img src={row.image} alt="" />
            <div>
              <p>
                {row.first_name} {row.last_name}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Rating</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
          style={{ width: "200px" }}
        >
          <StarRating count={row.rating}/>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Review</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
          style={{ width: "200px" }}
        >
          <div>
            <p onClick={row.comment.length > 20 ? () => openShowMore(row.comment) : null}>
              {row.comment.slice(0, 20)}
              {row.comment.length > 20 && "..."}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Reviewed On</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          {row.created_at && (
            <p>{format(new Date(row.created_at), "MM/dd/y")}</p>
          )}
        </div>
      ),
    },
  ];

  const [isTableLoaded, setIsLoaded] = useState(true);
  useEffect(() => {    
    fetchData();
  }, [totalCount]);

  const fetchData =(type='') =>{
    setIsLoaded(totalCount !== undefined);  
    if(type==1){ 
      handleFilterChange(filterData);
    } 
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    type: "sortBy",
    value: "newest",
  });

  const handleFilterChange = (type, value) => {
    handleSelectChange("reviews", type, value, currentPage);
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
    const newOffset = pageNumber - 1;
    const type = filterData.type;
    const value = filterData.value;
    handlePageChange("reviews", newOffset, type, value);
    setCurrentPage(newOffset);
  };

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
    //  console.log(offerDetails);
  };

  const handleClose = () => {
    setShow(false);
  };

  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState(false);

  const openShowMore = (review) => {
    setText(review);
    setShowMore(true);
  };

  const closeShowMore = () => {
    setShowMore(false);
  };

  return (
    <div
      className="tab-pane fade show active"
      id="reviews-tab-pane"
      role="tabpanel"
      aria-labelledby="review-tab"
      tabIndex="0"
    >
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-lg-8 mb-3 text-center text-lg-start">
          <h4 className="mb-0">Reviews</h4>
          <p>User reviews on this businesss that are approved by Aladinz will be displayed here. </p>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            {reviewsData?.filters?.map((filter, index) => (
              <div className="sort-sec my-2 me-2" key={index}>
                <p className="me-1">{filter.title} : </p>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    handleFilterChange(filter.type, e.target.value)
                  }
                >
                  {filter.options?.map((option) => (
                    <option value={option.value}>{option.option}</option>
                  ))}
                </select>
              </div>
            ))}
            {/* Button trigger modal */}
            {/* <button
              type="button"
              className="btn btn-primary my-2"
              onClick={handleShow}
            >
              Add Review
            </button> */}
          </div>
        </div>
      </div>
      <div className="">
          <DataTableList  pageChange={onPageChange}   totalCount={reviewsData.totalCount}  columns={columns}   tableData={reviewsData?.businessReviews}  type ={'reviews'} isTableLoaded={isTableLoaded} />           
        </div> 
      {/* <div className="row mt-2">
        <div className="col-12">
          <div className="list-table search-reports-list">
            <DataTable
              columns={columns}
              data={reviewsData?.businessReviews} // Use the prop directly
              pagination
              paginationServer
              paginationTotalRows={reviewsData.totalCount}
              onChangePage={onPageChange}
              paginationComponentOptions={{
                noRowsPerPage: true,
                selectAllRowsItem: false,
              }}
              
            />

          </div>
        </div>
      </div> */}
      <AddReview {...{ show, handleClose, businessKey, fetchData }} />
      <ShowMoreModal {...{ showMore, closeShowMore, text }} />
    </div>
  );
}

export default Reviews;

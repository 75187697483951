import React, {useState , useEffect} from 'react'; 
import { Link } from 'react-router-dom';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { MakeAxiosRequest } from '../../utils/handler';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import businessimg from '../../Assets/Images/business-img.png';
const ListingComponent = ({ businessData,handlePageChange,handleSelectChange  }) => {
  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "Inactive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];
  const filters = businessData?.filters || [];
  const businessList = businessData['agentBusinessArr'];
  const totalCount = businessData?.totalcount;

  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const [isTableLoaded, setisTableLoaded] = useState(true);
   
  const handleFilterChange = (type, value) => {
    
    handleSelectChange('business',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('business',newOffset,type,value);   
    setCurrentPage(newOffset);
  };
   const agentData =() =>{
    setisTableLoaded(totalCount !== undefined);     
   };

  useEffect(() => { 
    agentData()
  }, [totalCount]);


   // Status change of businesses 
   const changeStatus = async (businessKey, status) => {
    try {
    
     
      const result = await Swal.fire({
        title: 'Are you sure?',
        html: 'Are you sure you want to '+status+'  this business?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + status,
        customClass: {  confirmButton: 'btn btn-primary'  },
        cancelButtonText: 'Cancel',
      //  showLoaderOnConfirm: true,
        reverseButtons: true,  
        preConfirm: async () => {
          try {
            
            const res = await MakeAxiosRequest(
              'post', {"status":status}, `business/${businessKey}/changestatus`
            );        
            if (res.status === 1) {
              return true; 
            } else {
              throw new Error(res.message);
            }
          } catch (error) {
             Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },         
      });
      if (result.isConfirmed) {
        if (result.value === true) {
          $('#tranfer-account').modal('hide');
          Swal.fire({ icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, 
           text: 'Business ' + status + 'd successfully.'
          });
          handleFilterChange(filterData);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',
      });
    }
  };



  const columns = [    
    {    
      name: <div className="datatable-hd"><h6>Name</h6></div>,
      selector: row => (
        <UserRowActions  row={{ primaryData: row.title, imagePath :row.image_path|| businessimg,          
          viewUrl: `/business/${row.business_uuid}` 
        }}/>  
      ),
       
    },   
    {
      name: <div className="datatable-hd"><h6>Address</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <p>{row.address}</p>      
        </div>),   
    },
    {
      name: <div className="datatable-hd"><h6>Joined Date</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.created_at), 'dd/MM/y')}</p>      
        </div>),   
    },
    {
      name: <div className="datatable-hd"><h6>Subscription Date</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.start_date), 'dd/MM/y')} - {format(new Date(row.end_date), 'dd/MM/y')}</p>      
        </div>),   
    },
    // {
    //   name: <div className="datatable-hd"><h6>Start Date</h6></div>,
    //   selector: row => (
    //     <div data-title="" className="numeric data-table-sec">
    //       <p>{format(new Date(row.start_date), 'dd/MM/y')}</p>      
    //     </div>),   
    // },
    // {
    //   name: <div className="datatable-hd"><h6>End Date</h6></div>,
    //   selector: row => (
    //     <div data-title="" className="numeric data-table-sec">
    //       <p>{format(new Date(row.end_date), 'dd/MM/y')}</p>      
    //     </div>),   
    // },
    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          {row.is_expired=='Expired' ?
                    <span className="status-tag status-tag-inactive">{row.is_expired}</span>
:
statusOptions.map(option => {
            return row.status === option.value && (
              <span key={option.value} className={option.className}>{option.label}</span>
            );
          })
        }
          
        </div>
      ),
    }, 
    {
      selector: row => (
        <div data-title="" className="numeric scrolview data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li><Link className="dropdown-item" to={`/business/${row.business_uuid}`}><i className="fa-solid fa-eye me-2"></i>View </Link></li>
                {/* <li><Link  className="dropdown-item danger" to={`/businesses/edit/${row.business_uuid}`} ><i className="fa-solid fa-pen me-2"></i>Edit</Link></li> */}
                {/* {(() => {
                  switch (row.status) {
                    case "0":
                      return (
                        <li><a href="javascript:void(0)" className="dropdown-item success" onClick={() => changeStatus(row.business_uuid,'activate')}><i className="fa-solid  fa-square-check me-2"></i>Activate</a></li>
                      );
                    case "1":
                      return (
                        <>
                          <li><a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(row.business_uuid,'deactivate')} ><i className="fa-regular fa-trash-can me-2"></i>Deactivate</a></li>
                        </>
                      );
                    default:
                      return null;
                  }
                })()} */}
              </ul>
            </div>
          </div>
        </div>
      ),
    },      
  ];

  return (
    <>
     <div class="tab-pane fade business show active" id="business-tab-pane" role="tabpanel" aria-labelledby="business-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
      <div class="col-12 col-lg-6 mb-3 text-center text-lg-start">
          <h4 class="mt-3">Businesses</h4>
          <p class="mb-0 tab-hd-p">Businesses that this user has added are listed on this page. </p>
        
        </div>
        <div class="col-lg-6 mb-3">
          <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
              {filters?.map((filter, index) => (
                <div className="sort-sec my-2 me-2" key={index}>
                  <p className="me-1">{filter.title} : </p>
                  <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                    {filter.options?.map( option => 
                      <option value={option.value} >{option.option}</option>
                    )}                        
                  </select>
                </div>
              ))}
            
          </div>
        </div>
        </div>
        <div className="">          
            <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}  tableData={businessList}  type ={'business'} isTableLoaded={isTableLoaded}/>    
        </div>       
      </div>
    </>
  );
};

export default ListingComponent;

import React from "react";
import noRecords from "../../Assets/Images/no-records.png";

function NoDataFound({text}) {
  return (
    <div>
      <div className="my-4 text-center">
        <img
          src={noRecords}
          style={{ width: "150px" }}
          alt="No records found"
        />
        <div className="mt-3">There are no {text || "data"} to display.</div>
      </div>
    </div>
  );
}

export default NoDataFound;

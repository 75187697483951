import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import InputField from "../../Components/Inputs/InputField";
import { FormProvider, useForm } from "react-hook-form";
import SelectField from "../../Components/Inputs/SelectField";
import dummyProfile from "../../Assets/Images/dummy-profile.jpg";
import Swal from "sweetalert2";
import { MakeAxiosRequest } from "../../utils/handler";
import AmountField from "../../Components/Inputs/AmountField";


function CreateEditModal({
  showEdit,
  handleCloseEdit,
  isEdit,
  subscriptionPlanDetails,
  fetchData,
  perPage,
  handlePageChange,
  handleResetPagination
}) {
  const [tenureTypes, setTenureTypes] = useState([]);

  const methods = useForm({
    defaultValues: {
      first_name: subscriptionPlanDetails.subscription_name || "",
      amount: subscriptionPlanDetails.amount || "",
      validity_type: subscriptionPlanDetails.tenure_type_id || "",
    
    },
  });

  const { handleSubmit, reset } = methods;
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    e.preventDefault();
    const { value = "" } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setValue(parsedValue);
  };
  const handleOnBlur = () => setValue(Number(value).toFixed(2));
  const fetctDetails = async () => {
    try {
      const response = await MakeAxiosRequest(
        "post",
        {},
        "/subscription/fetchdetails"
      );
      // console.log(response)
      if (response.status == 1) {
        const tenureTypes = response.data.tenureTypes?.map(item => ({
          // Transform each item as needed
          id: item.id,
          name: item.tenure_type,
        }));
        console.log(tenureTypes);
        setTenureTypes(tenureTypes);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const onSubmit = (data) => {
    if (isEdit) {
      updateSubscription(data);
    } else {
      createSubscription(data);
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createSubscription = async (data) => {

    try {
      setIsSubmitting(true);
      const response = await MakeAxiosRequest("post", data, "/subscription/store");
      // console.log(response);
      if (response.status == 1) {      
        const subscriptionKey = response.data.subscriptionPlanDetails.subscription_plan_uuid;
        reset();
        handleCloseEdit();
        Swal.fire({  icon: "success", customClass: {
          confirmButton: 'btn btn-primary'
        }, title: "Success!", text: response.message,  });         
        handlePageChange(0);  
        handleResetPagination();     
        } else {       
          Swal.fire({  icon: "error", customClass: {
            confirmButton: 'btn btn-primary'
          }, title: "Error!", text: response.message,  });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }finally {
      setIsSubmitting(false);
    }
  };

  const updateSubscription = async (data) => {
    try {
      setIsSubmitting(true);
      const response = await MakeAxiosRequest(
        "post",  {...data}, `/subscription/${subscriptionPlanDetails?.subscription_plan_uuid}/update`
      );
      if (response.status == 1) {
        reset();
        handleCloseEdit();
        Swal.fire({icon: "success", customClass: {
          confirmButton: 'btn btn-primary'
        }, title: "Success!", text: response.message, });
        fetchData(1, perPage);      
      } else {      
        Swal.fire({  icon: "error", customClass: {
          confirmButton: 'btn btn-primary'
        }, title: "Error!", text: response.message,  });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }finally {
      setIsSubmitting(false);
    }
  };




  const fields = [
    {
      name: "subscription_name",
      label: "Subscription Name",
      rules: { required: "Please enter subscription name" },
      type: "inputfield",
      className:"col-md-6 mb-3",
      value: subscriptionPlanDetails.subscription_name,
    },
    {
      name: "amount",
      label:  "Amount",
      icon: <i className="fas fa-indian-rupee-sign"></i>,
      rules: {
        required: "Please enter amount",
        pattern: {
          value: /^\d+(\.\d+)?$/,
          message: "Please enter valid Amount",
        },
        validate: {
          notZero: value => parseFloat(value) !== 0 || "Amount cannot be 0",
        },
      },
       
      className:"col-md-6 mb-3",
      type: "inputfield",
    },
    {
      name: "tenure_type_id",
      label: "Tenure Type",
      rules: { required: "Please select tenure type" },
      type: "select",
      className:"col-md-4 mb-3",
      options: tenureTypes,
    },
    {
      name: "tenure_period",
      label: "Tenure Period",
      rules: {
        required: "Please enter tenure period",
        pattern: {
          value: /^[0-9]+$/,
          message: "Please enter valid Tenure period",
        },
      },
      type: "inputfield",
      className:"col-md-4 mb-3",
      value: subscriptionPlanDetails.tenure_period,
    },
    {
      name: "no_of_offers",
      label: "Offers Available",
      rules: {
      //  required: "Offer is required",
        pattern: {
          value: /^[0-9]+$/,
          message: "Please enter valid Offer",
        },
      },
      type: "inputfield",
      className:"col-md-4 mb-3",
      value: subscriptionPlanDetails.no_of_offers,
    },
   
  ];

  useEffect(() => {
    fetctDetails();
  }, []);

  useEffect(() => {
  
    //const formattedValue = parseFloat(subscriptionPlanDetails.amount).toString();
    let offers;

    if (isEdit) {
      offers = subscriptionPlanDetails.no_of_offers || "0";
    } else {
      // You can set a default value or handle it differently if needed
      offers = "";
    }
    reset({
      subscription_name: subscriptionPlanDetails.subscription_name || "",
      amount: subscriptionPlanDetails.amount || "",
      tenure_type_id: subscriptionPlanDetails.tenure_type_id || "",
      no_of_offers: offers || "",
      tenure_period: subscriptionPlanDetails.tenure_period || "",
    });
  }, [showEdit, isEdit, subscriptionPlanDetails]);

  return (
    <>
      <Modal show={showEdit} 
        // onHide={handleCloseEdit}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
        {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
        <div className="modal-content">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-4">
                    <div className="text-center">
                      <h5>
                        {isEdit ? "Edit Subscription Plan" : "Create a Subscription Plan"}
                      </h5>
                      <p className="gray">
                        {isEdit
                          ? ""
                          : ""}
                      </p>
                    </div>
                   
                  </div>
                </div>

                <div className="row mt-3">
               
                {fields?.map((field, index) => {
                  switch (field.type) {
                    case "inputfield":
                      return (
                      
                       
                        <InputField key={index}  {...field}  />
                      
                   
                         
                      );
                      case "amountfield":
                        return(
                          <AmountField key={index} {...field}  onChange={handleChange}
                          onBlur={handleOnBlur}  value={value}/>
                        );
                    case "select":
                      return <SelectField key={index} {...field} />;
                    default:
                      return null;
                  }
                })}

                
                </div>
              </div>

              <div className="modal-footer b-0 mt-3">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary"   disabled={isSubmitting}>
                  {isEdit ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
        {/* </div> */}
        {/* </div> */}
      </Modal>
    </>
  );
}

export default CreateEditModal;

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { MakeAxiosRequest } from "../../utils/handler";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import loaderFile from "../../Assets/Images/loader.gif";
import NoDataFound from "../../Components/Listing/NoDataFound";
import ShowMoreModal from "../../Components/Listing/ShowMoreModal";
import StarRating from "../../Components/Listing/StarRating";
import ViewModal from "../../Components/Listing/ViewModal";
function Listing({ searchValue }) {

    
  const navigate = useNavigate();
  const params = useParams();
  const sortby = params.sortby || "newest";
  const status = params.status || "pending";

  const columns = [
    {
      name: (
        <div className="datatable-hd" >
          <h6>Name</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
  
           <Link to={row.user_status !== '2' ? `/user/${row.user_uuid}` :  "javascript:void(0)"     }>
            <div className="profile-sec">
              <img src={row.profile_img} alt="" />
              <div>
                <p>{row.first_name && row.first_name} {row.last_name && row.last_name}</p>
                <span>{format(new Date(row.created_at),  'dd/MM/y')}</span>
              </div>
            </div>
          </Link>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Business</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
  
           <Link to={`/business/${row.business_uuid}`}>
  
            <div className="profile-sec">
              <img src={row.business_img} alt="" />
              <div>
                <p>{row.title && row.title}</p>
              </div>
            </div>
          </Link>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Rating</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          {row.rating && <StarRating count={row.rating}/>}
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Review</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric  data-table-sec">
          <a href="javascript:void(0)" onClick={() => viewReview(row)}>
            <p>{row.comment.slice(0, 15)}{row.comment.length > 15 && <a>...</a>}</p>
            </a>
        </div>
      ),
    },
    
   
    {
      name: (
        <div className="datatable-hd">
          <h6>Updated by</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
  {row.updated_by && 
  <>
           <Link to={`/user/${row.updated_by_uuid}`}>
            <div className="profile-sec">
              <img src={row.updated_by_image} alt="" />
              <div>
                <p>{row.updated_by && row.updated_by}</p>
              </div>
            </div>
          </Link>
          </>
    }
        </div>
      ),
    },
    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          {statusOptions.map(option => {
            return row.status === option.value && (
              <span key={option.value} className={option.className}>{option.label}</span>
            );
          })}
       </div>
      ),
    },
    {
      selector: row => (
        <div data-title="" className="numeric scrolview data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
              <li><a href='javascript:void(0)' className="dropdown-item" onClick={() => viewReview(row)}><i className="fa-solid  fa-eye me-2"></i>View</a></li>
                {(() => {
                  switch (row.status) {
                    case "-1":
                      return (
                        <>
                         <li><a href='javascript:void(0)' className="dropdown-item success" onClick={() => changeStatus(row.rating_uuid,'approve')}><i className="fa-solid  fa-square-check me-2"></i>Approve</a></li>
                          <li><a href='javascript:void(0)'  className="dropdown-item reject" onClick={() => changeStatus(row.rating_uuid,'reject')} ><i className="fa-regular fa-trash-can me-2"></i>Reject</a></li>
                        </>
                      );
                    case "1":
                      return (
                        <>
                          <li><a href='javascript:void(0)'  className="dropdown-item reject" onClick={() => changeStatus(row.rating_uuid,'reject')} ><i className="fa-regular fa-trash-can me-2"></i>Reject</a></li>
                        </>
                      );
                    case "2":
                      return (
                        <>
                          <li><a href='javascript:void(0)' className="dropdown-item success" onClick={() => changeStatus(row.rating_uuid,'approve')}><i className="fa-solid  fa-square-check me-2"></i>Approve</a></li>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const statusOptions = [
    { value: "-1", className: "status-tag status-tag-pending", label: "Pending" },
    { value: "2", className: "status-tag status-tag-inactive", label: "Rejected" },
    { value: "1", className: "status-tag status-tag-active", label: "Approved" },
  ];

  const viewReview = (review) => {
    console.log("view");
    setshowView(true)
    setReview(review)
  };

  const changeStatus = async (reviewKey, status) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to " + status + " this review?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes," + status,
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        reverseButtons: true,
        //showLoaderOnConfirm: true
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          { status: status },
          `review/${reviewKey}/changestatus`
        );

        if (res.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: res.message,
          });
          fetchData(1, perPage);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to change the status.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while changing the status.",
      });
    }
  };

  //const playerInstance = new DotLottiePlayer();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isStatusChange, setStatusChange] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [listingData, setlistingData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("pending");
  const { setBreadcrumbsData } = useBreadcrumb();
  const [showView, setshowView] = useState(false);
  const [Review, setReview] = useState({});
  const handleCloseView = () => setshowView(false);
  const [listParams, setListParams] = useState({
    offset: "0",
    status: status,
    sortBy: sortby,
  });

  console.log(showView)
  // Breadcrumbs
  const updateBreadcrumbs = () => {
    setBreadcrumbsData([{ label: "Reviews" }, { label: "Listing" }]);
  };
  useEffect(() => {
    updateBreadcrumbs();
  }, []);

  const fetchData = async (page, per_page) => {
    const res = await MakeAxiosRequest(
      "post",
      { ...listParams, searchValue },
      "/reviews"
    );
    setStatusChange(true);
    setIsLoaded(true);
    setItems(res.data.reviews);
    setFilters(res.data.filters);
    setTotalRows(res.data.totalcount);
  };
  useEffect(() => {
    fetchData(1, perPage);
  }, [listParams, searchValue, perPage]);

  const handlePageChange = (page) => {
    const newOffset = page - 1;
    setListParams((prevParams) => ({
      ...prevParams,
      offset: newOffset.toString(),
    }));
  };

  const handleResetPagination = () => {
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const handleSelectChange = (filterName, value) => {
    setStatusChange(false);
    if(filterName==='sortBy')
      navigate('/reviews/'+value+'/'+listParams.status);   
    if(filterName==='status')
      navigate('/reviews/'+listParams.sortBy+'/'+value);
    setListParams((prevParams) => ({ ...prevParams, [filterName]: value, offset:'0' }));
  };


  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState(false);

  const openShowMore = (review) => {
    setText(review);
    setShowMore(true);
  };

  const closeShowMore = () => {
    setShowMore(false);
  };


  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        
        <center>
          <img src={loaderFile} style={{ width: "125px" }} alt="Loading..." />
        </center>
      </div>
    );
  } else {
    return (
      <>
        <div className="row d-flex align-items-center flex-wrap ">
          <div className="col-xl-5">
            <div className="d-flex justify-content-center justify-content-xxl-start"></div>
          </div>
          <div className="col-xl-7">
            <div className="d-flex align-content-center justify-content-center justify-content-md-end flex-wrap">
              {filters?.map((filter, index) => (
                <div className="sort-sec my-2 me-2" key={index}>
                <p className="me-1">{filter.title} : </p>
                <select   className="form-select" aria-label="Default select example"  onChange={(e) => handleSelectChange(filter.type, e.target.value)} >
                {filter.options?.map((option) => (
                  <option key={option.value} value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                ))}

                  </select>
                </div>
              ))}

            </div>
          </div>
        </div>

        {isStatusChange ? (
          <div className="row mt-4">
            <div className="col-12">
              <div className="list-table table-responsive">
                <DataTable
                  columns={columns}
                  data={items}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  paginationPerPage={perPage}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  noDataComponent={<NoDataFound text="reviews"/>}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <center>
              <img src={loaderFile} style={{ width: "125px" }} alt="Loading..." />
            </center>
          </div>
        )}

        <ShowMoreModal {...{ showMore, closeShowMore, text }} />
        {showView && <ViewModal {...{ showView, handleCloseView,Review}} />}
         {/* <Modal
         show={showView}
        //  onHide={handleCloseView}
         dialogClassName="modal-dialog modal-dialog-centered modal-lg"
         >
gdfgdg
         </Modal> */}
      </>
    );
  }
}

export default Listing;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function ShowMoreModal({ showMore, closeShowMore, text,title }) {

  const nl2br = (text) => {
    if (typeof text !== 'string') {
      return text;
    }
  
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };
  return (
    <>
      <Modal
        show={showMore}
        // onHide={closeShowMore}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="text-center">
                    <h5>{title ? title : 'Review'}</h5>
                    <p className="gray">
                      {nl2br(text)}
                    </p>
                  </div>
                </div>
              </div>
              
            </div>

            <div className="modal-footer b-0">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={closeShowMore}
              >
                Close
              </button>
            </div>
          
      </Modal>
    </>
  );
}

export default ShowMoreModal;

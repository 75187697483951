import React, {useState, useEffect} from 'react';
import { format } from "date-fns";
import dummyBanner from "../../Assets/Images/dummy.jpg";
import loaderFile   from '../../Assets/Images/loader.gif';
import CreateEditModal from "../Offers/CreateEditModal";
import NoDataFound from "../../Components/Listing/NoDataFound";
import offerimg from '../../Assets/Images/offers.png';
import Swal from "sweetalert2";
import { MakeAxiosRequest } from "../../utils/handler";
import ListPagination from "../../Components/Listing/ListPagination";

const ListingComponent = ({ offersData, perPage, businessId,handlePageChange,handleSelectChange }) => {

  const statusOptions = [
    {
      value: "0",
      className: "status-tag status-tag-inactive",
      label: "InActive",
    },
    { value: "1", className: "offer-tag approved-tag", label: "Approve" },
    {
      value: "2",
      className: "status-tag status-tag-pending",
      label: "Pending",
    },
  ];

  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const totalCount = offersData?.totalcount;
  useEffect(() => {
    fetchData();
  }, [totalCount]);

  const fetchData =(type='') =>{
     setIsLoaded(totalCount !== undefined);  
    if(type==1){ 
      handleFilterChange(filterData);
    } 
 };

 
  const handleFilterChange = (type, value) => {
    handleSelectChange('offers',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    const type = filterData.type;
    const value = filterData.value;
    handlePageChange("offers", newOffset, type, value);
    setCurrentPage(newOffset);
  };



  const [showEdit, setshowEdit] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [offerDetails, setofferDetails] = useState({});

  const handleShow = () => {
    if(offersData?.isexpired==1){
      Swal.fire({
        icon: "error",
        title: "Subscription Plan is not started yet !",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "You are unable to add an offer since the subscription plan will only be starting from "+offersData?.subscriptionStartDate+'.',
      });
    }else if(offersData?.isActive==0){
      Swal.fire({
        icon: "error",
        title: "Request Denied !",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "You are unable to create an offer since you do not have an active subscription. Please renew the subscription to add the offer.",
      });
    }else{
      setisEdit(false);
      setshowEdit(true);
      setofferDetails({});
    }
    //  console.log(offerDetails);
  };

  const handleshowEdit = (user) => {
    setisEdit(true);
    setshowEdit(true);
    setofferDetails(user);
    // console.log(user);
  };
  const handleCloseEdit = () => {
    setshowEdit(false);
    setisEdit(false);
    setofferDetails({});
  };

  const changeStatus = async (offer, status) => {
    var msg = status == "approve" ? "activate" : (offer.status =='1' && status=='reject') ? 'deactivate' : status;
    console.log(offer)
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to " + msg + " this offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, " + msg,
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          { status: status },
          `offer/${offer.offer_uuid}/changestatus`
        );
        if (res.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Offer " + msg + "d successfully.",
          });
          handleFilterChange(filterData);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to change the status.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while changing the status.",
      });
    }
  };

  const markPremium = async (offerKey) => {
    console.log(offerKey);
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to mark this offer as premium?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          {},
          `offer/${offerKey}/markaspremium`
        );
        if (res.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Offer marked as premium.",
          });
          handleFilterChange(filterData);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to upgrade the offer.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while upgrading the offer.",
      });
    }
  };

  const UnMarkPremium = async (offerKey) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to unmark premium from this offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          {},
          `offer/${offerKey}/markasunpremium`
        );
        if (res.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Succesfully unmarked this offer from premium.",
          });
          handleFilterChange(filterData);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to downgrade the offer.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while downgrading the offer.",
      });
    }
  };
  
  const renderTextWithLineBreaks = (data) => {
    const html = data.replace(/\n/g, "<br />");
    return { __html: html };
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <>
      <div
        class="tab-pane fade show active"
        id="offers-tab-pane"
        role="tabpanel"
        aria-labelledby="followers-tab"
        tabindex="0"
      >
        <div className="row d-flex align-items-center justify-content-center flex-wrap ">
          <div className="col-lg-7 mb-3 text-center text-lg-start">
            <h4 className="mb-0">Offers</h4>
            <p>Offers added in by Aladinz/ Business users that are approved by Aladinz will be displayed here. </p>
          </div>
          <div className="col-lg-5 mb-3">
            <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            {offersData?.filters?.map((filter, index) => (
                  <div className="sort-sec my-2 me-2" key={index}>
                    <p className="me-1">{filter.title} : </p>
                    <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                      {filter.options?.map( option => 
                        <option value={option.value} >{option.option}</option>
                      )}                        
                    </select>
                  </div>
                ))}
              {/* Button trigger modal  */}
              <button
                type="button"
                className="btn btn-primary my-2"
                onClick={handleShow}
              >
                Add Offer
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
          {!isLoaded ? (<div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>):(
            <div class="row">
            {offersData?.businessOffers?.length > 0 ? (<>
            {offersData?.businessOffers?.map((offers, optionIndex) => (
              <div className="col-md-6 col-lg-4 col-xl-4 mb-3" key={optionIndex}>
                <div className="card offers-card">
                    <div className="offers-card-hd position-relative">
                      <img
                        className="offer-banner"
                        src={
                          offers.banner_image_path
                            ? offers.banner_image_path
                            : dummyBanner
                        }
                        alt=""
                      />
                      <div className="offer-optn mt-3">
                        <span></span>
                        <div className="d-flex align-items-center">
                          {offers.is_exclusive === "1" && (
                            <span className="offer-tag  premium-tag">
                              <i className="fa-regular fa-gem me-1"></i>Premium
                            </span>
                          )}
                          <div className="btn-group option-btn">
                            <a
                              href=""
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              data-bs-display="static"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  className="dropdown-item"
                                  onClick={() => handleshowEdit(offers)}
                                >
                                  <i className="fa-solid fa-pen me-2"></i>Edit
                                </a>
                              </li>
                              {(() => {
                                switch (offers.status) {
                                  case "-1":
                                    return (
                                      <>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item success"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "approve"
                                              )
                                            }
                                          >
                                            <i className="fa-solid  fa-square-check me-2"></i>
                                            Approve
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item reject"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "reject"
                                              )
                                            }
                                          >
                                            <i className="fa-regular fa-trash-can me-2"></i>
                                            Reject
                                          </a>
                                        </li>
                                      </>
                                    );
                                  case "1":
                                    return (
                                      <>
                                        {offers.is_exclusive == 1 ? (
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              className="dropdown-item"
                                              onClick={() =>
                                                UnMarkPremium(offers.offer_uuid)
                                              }
                                            >
                                              <i class="fa-regular fa-rectangle-xmark me-2"></i>
                                              Unmark Premium
                                            </a>
                                          </li>
                                        ) : (
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              className="dropdown-item"
                                              onClick={() =>
                                                markPremium(offers.offer_uuid)
                                              }
                                            >
                                              <i className="fa-regular fa-square-check me-2"></i>
                                              Mark as premium
                                            </a>
                                          </li>
                                        )}
                                        {/* <li><a href="javascript:void(0)" className="dropdown-item" onClick={() => markPremium(offers.offer_uuid)}><i className="fa-regular fa-square-check me-2"></i> Mark as premium</a></li> */}
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item reject"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "reject"
                                              )
                                            }
                                          >
                                            <i className="fa-regular fa-trash-can me-2"></i>
                                            Deactivate
                                          </a>
                                        </li>
                                      </>
                                    );
                                  case "2":
                                    return (
                                      <>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item success"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "approve"
                                              )
                                            }
                                          >
                                            <i className="fa-solid  fa-square-check me-2"></i>
                                            Activate
                                          </a>
                                        </li>
                                      </>
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body offer-dtls">
                      {/* {!!businessId || 
                        <div className="mb-3">
                          <span className="mb-0">Business Name</span>
                          <p>{offers.subscriber_name}</p>
                        </div>
                      } */}
                      <div className="mb-3">
                        <span className="mb-0">Offer Title</span>
                        <p>{offers.title}</p>
                      </div>
                      <div className="mb-3">
                        <span className='mb-0'>Offer Description</span>
                        <p className='description-sec offer-dscriptn'>
                          <span dangerouslySetInnerHTML={renderTextWithLineBreaks(showFullDescription ? offers.description : (offers.description.length > 50 ? `${offers.description.slice(0, 50)}...` : offers.description))} />
                          {offers.description.length > 50 && (
                            <a className="read-more"  href="javascript:void(0)" onClick={toggleDescription}>
                              {showFullDescription ? ' Read Less' : ' Read More'}
                            </a>
                          )}
                        </p>


                      </div>
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <span className="mb-0">Start date</span>
                            <p>
                              {format(new Date(offers.start_date),  'dd/MM/y')}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <span className="mb-0">End date</span>
                            <p>
                              {format(new Date(offers.end_date),  'dd/MM/y')}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="offer-img-sec">
                        <span className="">Offer Image</span>
                        <div className="img-sec">
                          <img
                            className="me-2"
                            src={
                              offers.card_image_path
                                ? offers.card_image_path
                                : offerimg
                            }
                          />
                          <p class="mb-0">{offers.original_name.length < 15 ? offers.original_name : `${offers.original_name.slice(0, 15)}...`}</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
              </div>

            ))}
                              <ListPagination pageChange={handlePageClick} totalCount={offersData.totalcount}/>

            </>) : <NoDataFound text="offers"/>}
            </div>
          )}
          </div>
        </div>

        <CreateEditModal
          {...{
            showEdit,
            handleCloseEdit,
            isEdit,
            offerDetails,
            fetchData,
            perPage,
            businessId,
          }}
        />
      </div>
    </>
  );
};

export default ListingComponent;

import React, {useState , useEffect,useRef} from 'react';   
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import loaderFile   from '../../Assets/Images/loader.gif';
import { MakeAxiosRequest } from '../../utils/handler';
const ListingComponent = ({ subData,handlePageChange }) => {

    
  const userSubscriptions = subData?.userSubscriptions || [];
  const totalCount = subData?.totalcount;
  const [error, setError] = useState(null);
  const [isLoaded, setIssubLoaded] = useState(true); 

  const [isTableLoaded, setisTableLoaded] = useState(true);
  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1);
    handlePageChange('subscriptions', newOffset);
  };
//const   isloading  = (totalCount !== undefined) ? false : true;
  
const fetchData = () => {
  setisTableLoaded(totalCount !== undefined);
 
};

useEffect(() => {
  fetchData();
}, [totalCount]);
 
  

  
  
const changeStatus = async (subscriptionKey,status) => {
  try {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to '+status+' this subscription?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes,' +status,
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      const res = await MakeAxiosRequest(
        'post', {"status":status}, `subscription/${subscriptionKey}/changestatus`
      );
     
      if (res.status===1) {
        Swal.fire({  icon: 'success',  title: 'Success!',   customClass: {
          confirmButton: 'btn btn-primary'
        },
         text: 'Deleted successfully.',  });
         onPageChange(0);
      } else {
       
        Swal.fire({ icon: 'error',  title: 'Error',  customClass: {
          confirmButton: 'btn btn-primary'
        }, text: 'Failed to change the status.', });
      }     
    } 
  } catch (error) {
    Swal.fire({ icon: 'error',  title: 'Error',  
     customClass: {
      confirmButton: 'btn btn-primary'
    },
      text: 'An error occurred while changing the status.', }); 
  
  }
}

  const columns = [
 
    {
    
      name: <div className="datatable-hd" style={{width:'240px'}}><h6>Subscription Name</h6></div>,
      selector: row => ( <div data-title="" className="numeric data-table-sec"> <div className='truncate-name'><p>{row.subscription_name} </p> </div>  </div> ),
  
  },
    {
   
      name: <div className="datatable-hd"><h6>Amount</h6></div>,
      selector: row => (<div data-title="" className="numeric data-table-sec" > <p>{row.amount}</p> </div> ),   
    },
    {
      name: 'Offer available',
      selector: row => ( <div data-title="" className="numeric  data-table-sec" > <p>{row.no_of_offers}</p> </div> ),   
    }, 
    {
      name: <div className="datatable-hd"><h6>Created On</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.created_at), 'dd/MM/y')}</p>      
        </div>),   
    },  
    {
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a href="javascript:void(0)" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">                  
                <li>
                  <a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(row.subscription_plan_uuid, 'delete')}> 
                  <i className="fa-regular fa-trash-can me-2"></i>Delete </a>
                </li>             
              </ul>
            </div>
          </div>
        </div>
      ),    
    },
      
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  }  else {
    return (
    <>
      <div className="tab-pane fade subscriptions-tablist show active" id="subscriptions-tab-pane" role="tabpanel" aria-labelledby="subscriptions-tab" tabIndex="0">
        <div className="row d-flex align-items-center justify-content-center flex-wrap">
          <div className="col-12 mb-3 text-center text-lg-start">
            <h4 className="mt-3">Subscriptions</h4>
            <p className="mb-0">You can view all the subscription plans you have added to aladinz</p>
          </div>
        </div>
        <div className="">
        <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}   tableData={userSubscriptions}  type ={'subscriptions'} isTableLoaded={isTableLoaded} />  
          
        </div>
      </div>
    </>
  );

}
};

export default ListingComponent;

import React, {useState, useEffect} from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { MakeAxiosRequest } from '../../utils/handler';
import loaderFile   from '../../Assets/Images/loader.gif';
import { useDispatch, useSelector } from 'react-redux';
import { logout,update } from '../../features/authSlice';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Button, Modal } from "react-bootstrap";
import CreateEditModal from './CreateEditModal';



function Listing({searchValue}) {
 
  const navigate = useNavigate();
  const params = useParams();
  const type  = params.type || "all";
  const sortby = params.sortby || "newest";
  const status = params.status || "all";

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const statusOptions = [
  { value: "0", className: "status-tag status-tag-inactive", label: "Inactive" },
  { value: "1", className: "status-tag status-tag-active", label: "Active" },
  { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
];


const [showEdit, setshowEdit] = useState(false);
const [error, setError] = useState(null);
const [isLoaded, setIsLoaded] = useState(false);
const [items, setItems] = useState([]);
const [totalRows, setTotalRows] = useState(0);
const [perPage, setPerPage] = useState(10);
const [filters, setFilters] = useState([]);
const [userrolefilters, setUserrolefilters] = useState([]);
// const [selectedOption, setSelectedOption] = useState('all');
const { setBreadcrumbsData } = useBreadcrumb();
const [isTableLoaded, setisTableLoaded] = useState(true);
const [listParams, setListParams] = useState({
  offset: "", status: status, sortBy: sortby, userrole:type
});




const columns = [
  {
    name: <div className="datatable-hd"><h6>Name</h6></div>,
    selector: row => (
      <UserRowActions
        row={{
          primaryData: row.first_name,
          secondryData: row.last_name,
          imagePath :row.profile_img,
          sublistData: row.user_role,      
          viewUrl:row.status !== '2' ? `/user/${row.user_uuid}` :  "javascript:void(0)" 
        }}  />    
    ),
  },
  {
    name: <div className="datatable-hd"><h6>Email/Phone Number</h6></div>,
    selector: row => (
      <UserRowActions
          row={{
            primaryData: row.email, sublistData: row.phone_number,  
            viewUrl:row.status !== '2' ? `/user/${row.user_uuid}` :  "javascript:void(0)" 
          }}   />
    ),
  },
  {
    name:<div className="datatable-hd"><h6>Created By</h6></div>,
    selector: row => (
      <UserRowActions row={{ primaryData: row.created_by || 'via app', 
        sublistData: format(new Date(row.created_at),  'dd/MM/y'), 
        class:'disable-tag-click',}}/>    
      ),
  },
  {
    name: <div className="datatable-hd"><h6>Updated By</h6></div>,
    selector: row => (
      <UserRowActions  row={{ primaryData:  row.updated_by , 
            sublistData: row.updated_by !== '' ? format(new Date(row.updated_at),  'dd/MM/y') :  "" , 
            class:'disable-tag-click',  }}/>    
      ),     
  },
  {
    name: <div className="datatable-hd"><h6>Status</h6></div>,
    selector: row => (
      <div data-title="" className="numeric data-table-sec">
        {statusOptions.map(option => {
          return row.status === option.value && (
            <span key={option.value} className={option.className}>{option.label}</span>
          );
        })}
     </div>
    ),
  },
  {
    selector: row => (
      <UserRowActions
      row={{
        tableData: row,
        actionkey: row.user_uuid,
        actionrole: row.user_role,
        actionstatus: row.status,
        type:'action',
        isModal:'1',
        viewUrl:`/user/${row.user_uuid}`
      }}     
       handleshowEdit={handleshowEdit}
      
      changeStatus={changeStatus} />
    
     
    ),
  },
];

    // Status change of users 
  const changeStatus = async (userKey, status, type) => {
    try {
      const statusType = type.toLowerCase();    
      let showConfirmButton = true; 
      const result = await Swal.fire({
        title: 'Are you sure?',
        html: `Are you sure you want to ${status} this <br>${statusType}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + status,
        customClass: {  confirmButton: 'btn btn-primary'  },
        cancelButtonText: 'Cancel',
       // showLoaderOnConfirm: true,
        reverseButtons: true,  
        preConfirm: async () => {
          try {
            Swal.update({
              showConfirmButton: showConfirmButton
            });    
            const res = await MakeAxiosRequest(
              'post', {"status":status}, `user/${userKey}/changestatus`
            );         
            if (res.status === 1) {
              return true; 
            } else {
              throw new Error(res.message);
            }
          } catch (error) {
            console.error(error); // Log the error
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },
      
      });
      if (result.isConfirmed) {
        if (result.value === true) {
        
          Swal.fire({
            icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, text: type + ' ' + status + 'd successfully.'
          });
          fetchData();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',
      });
    }
  };


  useEffect(() => {
    fetchData();
  }, [listParams,searchValue])


  // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData([     
        { label: "Users" },
        { label: "Listing"},
       
      ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 
  

  const fetchData = async (page, per_page) => {      
    try {
       const res = await MakeAxiosRequest("post", {...listParams, searchValue}, "/users");
       setIsLoaded(true);      
       setItems(res.data.users);
       setUserrolefilters(res.data.userrolefilters); 
       const statusCounts = {
          all: res.data.totalcount,
          active: res.data.activecount,
          inactive: res.data.inactivecount,
          pending: res.data.pendingcount,
       };
       setisTableLoaded(true);
       const selectedStatus = listParams.status || 'active';
       setTotalRows(statusCounts[selectedStatus]);
       setFilters(res.data.filters); 
    } catch (error) {      
       console.error('Error fetching data:', error);
    }
 };
 

  const handlePageChange = page => {
   // setisTableLoaded(false); 
    const newOffset = (page - 1) ;
    setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));
  }

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   setPerPage(newPerPage);
  // }
  const handleSelectChange = (filterName,value) => {   
    setisTableLoaded(false); 
    if(filterName==='sortBy')
      navigate('/users/'+listParams.userrole+'/'+value+'/'+listParams.status);  
    if(filterName==='status')
      navigate('/users/'+listParams.userrole+'/'+listParams.sortBy+'/'+value);
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };

  const handleClickChange = (filterName,value) => {   
    navigate('/users/'+value+'/'+listParams.sortBy+'/'+listParams.status);
    setisTableLoaded(false); 
    // setSelectedOption(value);
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };

  
  const [isEdit, setisEdit] = useState(false);
  const [userDetails, setuserDetails] = useState({});

  



  const [show, setShow] = useState(false);


  const handleShow = () => {
    setisEdit(false)
    setshowEdit(true)
    setuserDetails({})
    console.log(userDetails);
  };


  const handleshowEdit = (user) => {
    setisEdit(true)
    setshowEdit(true)
    setuserDetails(user)
     console.log(user);
  };
  const handleCloseEdit = () => {
    setshowEdit(false);
    setisEdit(false)
    setuserDetails({})
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
    <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-xl-5 mb-3">
            <div className="d-flex justify-content-center justify-content-xl-start">
                <ul className="sort-btn-group">
                   {userrolefilters?.flatMap((filter) => filter.options)?.map((option, optionIndex) => (
                      <li key={optionIndex}>
                        <a  className={`btn tabview ${option.value === type ? 'active' : ''}`}   onClick={() => handleClickChange(userrolefilters[0].type, option.value)}>
                          {option.option}  
                        </a>
                      </li>
                    ))}
                </ul>
            </div>
          </div>
        <div className="col-xl-7 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-xl-end flex-wrap">
          {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option => 
                  <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                )}                        
              </select>
            </div>
          ))}        
             <Button className="btn btn-primary my-2" onClick={handleShow}>Create </Button>            
          </div>
          </div>
        </div>     
        <div className="">          
        <DataTableList  pageChange={handlePageChange}   totalCount={totalRows}  columns={columns}  tableData={items} customStyles={customStyles}  type ={'users'} isTableLoaded ={isTableLoaded}/>    
        </div>
        {showEdit &&  <CreateEditModal {...{ showEdit, handleCloseEdit,isEdit, userDetails,fetchData,perPage }} />}
      </>
    );
  }
}

export default Listing;

import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { MakeAxiosRequest } from "../../utils/handler";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import { format } from "date-fns";
import Facebook from "../../Assets/Images/social-media/facebook.png";
import Instagram from "../../Assets/Images/social-media/instagram.png";
import Twitter from "../../Assets/Images/social-media/twitter.png";
import Web from "../../Assets/Images/social-media/web.png";
import Overview from "./Overview";
import Users from "./Users";
import Followers from "./Followers";
import Offers from "./Offers";
import Reviews from "./Reviews";
import Reports from "./Reports";
import Services from "./Services";
import Gallery from "./Gallery";
import BillingInfo from "./BillingInfo";
import Swal from "sweetalert2";
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import loaderFile from "../../Assets/Images/loader.gif";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import Subscriptions from "./Subscriptions";
import { businessStatusChange } from "../../actions/business";

function BusinessDetail() {
  const params = useParams();

  const userKey = params.key;
  const tab = params.tab || 'overview';

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [tabList, setTabList] = useState([]);

  const [overviewdata, setOverviewData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [billingInfoData, setBillingInfoData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [listParams, setListParams] = useState({
    offset: "0",
    sortBy: "newest",
  });

  const { setBreadcrumbsData } = useBreadcrumb();
  const updateBreadcrumbs = () => {
    setBreadcrumbsData([
      { label: "Business", path: "/businesses" },
      { label: "Details" },
    ]);
  };
  useEffect(() => {
    updateBreadcrumbs();
  }, []);

  const fetchData = async () => {
    const res = await MakeAxiosRequest(
      "post",
      {},
      `/business/details/${userKey}`
    );
    if (res.status == "1") {
      setItems(res.data.businessdets);
      setTabList(res.data.tabs);
      const index = res.data.tabs.findIndex((item) => item.value === tab);
      if(index>0){
        changeTabList(tab);
        setActiveTab(index);
      }else{
        changeTabList('overview');
      }
      setIsloading(false);
    } else {
      navigate("/businesses");
    }
    // console.log(res.data);
  };

  useEffect(() => {
    setIsloading(true);
    fetchData();
    // changeTabList(tab);
    // setActiveTab(0);
  }, []);

  const handlePageChange = (page, newOffset, filterName, value) => {
    changeTabList(page, {
      ...listParams,
      offset: newOffset.toString(),
      [filterName]: value,
    });
  };

  const handleSelectChange = (page, filterName, value, newOffset) => {
    changeTabList(page, {
      ...listParams,
      [filterName]: value,
      offset: newOffset.toString(),
    });
  };

  const changeTabList = async (page, listParams) => {
    navigate('/business/'+userKey+'/'+page, { replace: true });
    const subres = await MakeAxiosRequest(
      "post",
      { ...listParams },
      `/business/${userKey}/${page}`
    );
    const dataTabMappings = {
      overviewarr: setOverviewData,
      businessusers: setUsersData,
      businessfollowers: setFollowersData,
      businessOffers: setOffersData,
      subscriptions: setSubscriptions,
      businessReviews: setReviewsData,
      businessReports: setReportsData,
      businessServices: setServicesData,
      businessAlbum: setGalleryData,
      businessBilling: setBillingInfoData,
    };

    const validTabProperty = Object.keys(dataTabMappings).find(
      (prop) => subres.data?.[prop]
    );
    // console.log(validTabProperty);
    if (validTabProperty) {
      dataTabMappings[validTabProperty](subres.data);
    }

    // const allTabs = document.getElementsByClassName("tab-pane");
    // const activeTabShow = document.getElementById(`${page}-tab-pane`);
    // $(`tab-pane`).removeClass("show active");
    // $(`${page}-tab-pane`).addClass("show active");
    // // allTabs && allTabs.classList.remove('show active');
    // activeTabShow && activeTabShow.classList.add('show');
    // activeTabShow && activeTabShow.classList.add('active');
  };

  const changeStatus = async (userKey, status) => {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          html:  "Are you sure you want to " + status + " this business ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Yes, ${status}`,
          customClass: { confirmButton: 'btn btn-primary' },
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          preConfirm: async () => {
            try {
              const res = await MakeAxiosRequest( "post",  { status: status }, `business/${userKey}/changestatus` );      
              if (res.status === 1) {
                return true;
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error(error); // Log the error
              Swal.fire({
                icon: 'warning',
                title: 'Request Failed!',
                customClass: { confirmButton: 'btn btn-primary' },
                text: "Failed to change the status.",
              });
              return false; 
            }
          },
        });    
        if (result.isConfirmed && result.value === true) {       
          Swal.fire({   icon: "success",    title: "Success!",  customClass: { confirmButton: "btn btn-primary",  },
            text: "Business " + status + "d successfully.",
          });
          fetchData();
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          customClass: { confirmButton: 'btn btn-primary' },
          text: 'An error occurred while changing the status.',
        });
      }
  };

  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = useState(0);

  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    // console.log(index);
    changeTabList(tabList[index].value);
    setActiveTab(index);
  };

  const viewTab = (tab) => {
    // console.log(tabList);
    const index = tabList.findIndex((item) => item.value === tab);
    changeTabList(tabList[index].value);
    setActiveTab(index);
    window.scrollTo(0, 0);
  };

  const [showFullTextLong, setShowFullTextLong] = useState(false);

  const toggleShowTextLong = () => {
    setShowFullTextLong(!showFullTextLong);
  };
  const [showFullTextShort, setShowFullTextShort] = useState(false);

  const toggleShowTextShort = () => {
    setShowFullTextShort(!showFullTextShort);
  };

  return (
    <>
      <div className="page">
        {/* Content Row */}
        <div className="row align-items-center">
          <div className="col-sm-10 col-lg-7">
          <div className="d-flex align-items-center mb-3">
            <a
              href="javascript:void(0)"
              onClick={() => navigate(-1)}
              className="back-btn"
            >
              <i class="fa-solid fa-circle-arrow-left me-1 me-sm-3"></i>
            </a>

            <Breadcrumbs />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div>
            <center>
              <img
                src={loaderFile}
                style={{ width: "125px" }}
                alt="Loading..."
              />
            </center>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-xl-8 col-xxl-3 mx-auto mb-3">
                  <div className="card cmpny-dtls">
                    <div className="cmpny-cover position-relative mb-4">
                      <img className="cmpny-cover-img" src={items.logo_img} />
                      <div className="company-prfl">
                        <img className="" src={items.profile_img} />
                      </div>
                    </div>
                    <div className="card-body mt-5">
                      <div className="text-center">
                        <h1>{items?.title}</h1>
                        <p className="mb-1">{items?.email}</p>
                        <p className="join-dt mb-1">
                          {items.created_at && (
                            <>
                              <span>Joined on: </span>
                              {format(new Date(items.created_at), "dd MMM yyy")}
                            </>
                          )}
                        </p>
                        {items.type && (
                          <p className="join-dt">
                            <span>Business Type: </span>{items.type}
                          </p>
                        )}
                      </div>
                      <div className="btn-group d-flex justify-content-center align-items-center">
                        <Link
                          to={`/businesses/edit/${userKey}`}
                          className="btn btn-primary m-1"
                        >
                          <i className="fa-solid fa-pen me-2"></i> Edit
                        </Link>
                        {(() => {
                          switch (items.status) {
                            case "0":
                              return (
                                <a
                                  className="btn btn-success-outline m-1"
                                  onClick={() =>
                                    changeStatus(items.business_uuid, "activate")
                                  }
                                >
                                  <i className="fa-solid  fa-square-check me-2"></i>
                                  Approve
                                </a>
                              );
                            case "1":
                              return (
                                <a className="btn btn-danger-outline m-1"
                                  onClick={() =>  changeStatus( items.business_uuid,   "deactivate"  )} >
                                  <i className="fa-solid fa-trash-can me-2"></i>
                                  Deactivate
                                </a>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </div>
                      <div className="cmpny-dtls-txt mt-4">
                        {items.short_description && 
                          <>
                            <span className="d-block mb-2">About (short)</span>
                            <p>
                              {items.short_description.length < 150 ? (
                                items.short_description
                              ) : (
                                <>
                                  {showFullTextShort
                                    ? items.short_description
                                    : `${items.short_description.slice(0, 150)}...`}
                                  <a
                                    href="javascript:void(0)"
                                    onClick={toggleShowTextShort}
                                  >
                                    {showFullTextShort ? "Read Less" : "Read More"}
                                  </a>
                                </>
                              )}
                            </p>
                          </>
                        }
                        {/* <p>{items.short_description}</p> */}
                        {items.owner_name && 
                          <>
                            <span className="d-block mb-2">Owner Name</span>
                            <p>{items.owner_name}</p>
                          </>
                        }
                        {/* <span className="d-block mb-2">Owner Phone Number</span>
                        <p>{items.owner_phone}</p> */}
                        <span className="d-block mb-2">Phone Number</span>
                        <p>{items.contact_number}</p>
                        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
                          <div>
                            <span className="d-block mb-2">
                              WhatsApp Number
                            </span>
                            <p className="mb-0">{items.whatsapp_number}</p>
                          </div>
                          <a
                            className="btn whtsapp-btn"
                            target="_blank"
                            // href={`https://wa.me/${items.whatsapp_number}`}
                            href={`https://wa.me/${items.whatsapp_number}?text=Hi,%20${encodeURIComponent(items.title)}`}
                          >
                            <i className="fa-brands fa-whatsapp me-2"></i>
                            Message
                          </a>
                        </div>
                        {items.category && 
                          <>
                            <span className="d-block mb-2">Category</span>
                            <p>{items.category}</p>
                          </>
                        }
                         {items.sub_category && 
                          <>
                            <span className="d-block mb-2">Sub Category</span>
                            <p>{items.sub_category}</p>
                          </>
                        }
                        <span className="d-block mb-2">Address</span>
                        <p>{items.address}</p>
                        {items.direction && <a className="btn btn-primary mb-3" href={items.direction} target="_blank"><i class="fas fa-location-arrow me-2"></i>Get Direction</a>}
                        <br/>
                        {(items.fb_link || items.insta_link || items.twitter_link || items.website) && (
                          <>
                            <span>Social Media</span>
                            <div className="d-flex mb-3 mt-2">
                              {items.fb_link && (
                                <a
                                  className="me-2"
                                  target="_blank"
                                  href={items.fb_link}
                                >
                                  <img src={Facebook} alt="Social Media" />
                                </a>
                              )}
                              {items.insta_link && (
                                <a
                                  className="me-2"
                                  target="_blank"
                                  href={items.insta_link}
                                >
                                  <img src={Instagram} alt="Social Media" />
                                </a>
                              )}
                              {items.twitter_link && (
                                <a
                                  className="me-2"
                                  target="_blank"
                                  href={items.twitter_link}
                                >
                                  <img src={Twitter} alt="Social Media" />
                                </a>
                              )}
                              {items.website && (
                                <a
                                  className="me-2"
                                  target="_blank"
                                  href={items.website}
                                >
                                  <img src={Web} alt="Social Media" />
                                </a>
                              )}
                            </div>
                          </>
                        )}
                        {items.long_description && 
                          <><span className="d-block mb-2">About (long)</span>
                          <p>
                            {items.long_description.length < 150 ? (
                              items.long_description
                            ) : (
                              <>
                                {showFullTextLong
                                  ? items.long_description
                                  : `${items.long_description.slice(0, 150)}...`}
                                <a className="mb-2"
                                  href="javascript:void(0)"
                                  onClick={toggleShowTextLong}>
                                {showFullTextLong ? "Read Less" : "Read More"}
                                </a>
                              </>
                            )}
                            </p>
                          </>
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-xl-12 col-xxl-9 mx-auto mb-3">
                  <div className="cmpny-dtls-tabs">
                    <Tabs
                      activeTab={activeTab}
                      onTabClick={onTabClick}
                      rightBtnIcon={<i class="fa-solid fa-angle-right"></i>}
                      leftBtnIcon={<i class="fa-solid fa-angle-left"></i>}
                      navBtnCLickAnimationDuration={1000}
                      tabsScrollAmount={3}
                      hideNavBtnsOnMobile={false}
                      animationDuration={1000}
                      tabsContainerClassName="scrtabs-tabs-fixed-container"
                      tabsUpperContainerClassName="scrtabs-tab-container"
                      navBtnAs="div"
                      navBtnClassName="navBtnClassName"
                      navBtnContainerClassName="navBtnContainerClassName"
                    >
                      {tabList.map((option, optionIndex) => (
                        <Tab key={optionIndex} className="tabbbb" tabAs="div">
                          <button
                            className={`nav-link ${option.value}-link ${
                              option.value === "overview" ? "active" : ""
                            }`}
                            type="button"
                          >
                            {option.option}
                          </button>
                        </Tab>
                      ))}
                    </Tabs>

                    <div className="tab-content" id="myTabContent">
                      {tabList.map((option, index) => (
                        <TabScreen
                          key={option.option}
                          activeTab={activeTab}
                          index={index}
                          // You can add animation with adding a custom class
                          className="some-animation-class"
                        >
                          {overviewdata && option.option === "Overview" && (
                            <Overview
                              overviewdata={overviewdata}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                              viewTab={viewTab}
                            />
                          )}
                          {usersData && option.option === "Users" && (
                            <Users
                              usersData={usersData}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                            />
                          )}
                          {followersData && option.option === "Followers" && (
                            <Followers
                              followersData={followersData}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                            />
                          )}
                          {offersData && option.option === "Offers" && (
                            <Offers
                              offersData={offersData}
                              businessId={items.id}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                            />
                          )}
                          {subscriptions &&
                            option.option === "Subscriptions" && (
                              <Subscriptions
                                subscriptions={subscriptions}
                                handlePageChange={handlePageChange}
                                handleSelectChange={handleSelectChange}
                                viewTab={viewTab}
                              />
                            )}
                          {reviewsData && option.option === "Reviews" && (
                            <Reviews
                              reviewsData={reviewsData}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                            />
                          )}
                          {reportsData &&
                            option.option === "Reported Issues" && (
                              <Reports
                                reportData={reportsData}
                                handlePageChange={handlePageChange}
                                handleSelectChange={handleSelectChange}
                              />
                            )}
                          {servicesData && option.option === "Services" && (
                            <Services
                              listData={servicesData}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                            />
                          )}
                          {galleryData && option.option === "Gallery" && (
                            <Gallery
                              listData={galleryData}
                              handlePageChange={handlePageChange}
                              handleSelectChange={handleSelectChange}
                            />
                          )}
                          {billingInfoData &&
                            option.option === "Billing Info" && (
                              <BillingInfo
                                billingInfoData={billingInfoData}
                                handlePageChange={handlePageChange}
                                handleSelectChange={handleSelectChange}
                              />
                            )}
                        </TabScreen>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BusinessDetail;

import React, {useState , useEffect} from 'react';
import { format } from 'date-fns';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { MakeAxiosRequest} from "../../utils/handler";
import Swal from 'sweetalert2';
import dummyProfile  from "../../Assets/Images/dummy-profile.jpg"
import CreateEditModal from './CreateEditModal';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";

const ListingComponent = ({ agentlistData,superAgentId,handlePageChange,handleSelectChange ,agentDetailsdata }) => {

  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "Inactive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];

  const filters = agentlistData?.filters || [];
  const subAgents = agentlistData?.subagents || [];
  const totalCount = agentlistData?.totalcount;
 
  const [perPage, setPerPage] = useState(10);
  const [showEdit, setshowEdit] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isTableLoaded, setisTableLoaded] = useState(false);
 

   
   const isSuperAgent =  1;
   const agentDetails =[];

   const handleShow = () => {
    setisEdit(false)
    setshowEdit(true)
   
  };
  
  const handleCloseEdit = () => setshowEdit(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const agentData =(type='') =>{
    setisTableLoaded(totalCount !== undefined);  
    if(type==1){ 
    handleFilterChange(filterData);
    }
   };
   
  const handleFilterChange = (type, value) => {
    handleSelectChange('subagents',type,value,currentPage);   
    agentDetailsdata();
    setFilterData({ type, value });
  };
  
  useEffect(() => {
    agentData()
  }, [totalCount]);

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('subagents',newOffset,type,value);   
    setCurrentPage(newOffset);
  };



  const changeStatus = async (agentKey,status) => {
    try {
      let showConfirmButton = true; 
      const result = await Swal.fire({
        title: 'Are you sure?',
        html: 'Are you sure you want to '+status+' this agent?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + status,
        customClass: {  confirmButton: 'btn btn-primary'  },
        cancelButtonText: 'Cancel',
        // showLoaderOnConfirm: true,
        reverseButtons: true,  
        preConfirm: async () => {
          try {
            Swal.update({ showConfirmButton: showConfirmButton  });    
            const res = await MakeAxiosRequest( 'post', {"status":status}, `agent/${agentKey}/changestatus`);
            if (res.status === 1) {
              return true; 
            } else {
              throw new Error(res.message);
            }
          } catch (error) {
            console.error(error); // Log the error
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },      
      });  
      if (result.isConfirmed) {
        if (result.value === true) {
          $('#tranfer-account').modal('hide');
          Swal.fire({  icon: 'success', title: 'Success!', customClass: {  confirmButton: 'btn btn-primary'  },           
            text: 'Agent ' + status + 'd successfully.'
          });
          handleFilterChange(filterData);
        }
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',  });
    }
    
  }

  const columns = [
        {
          name: <div className="datatable-hd"><h6>Name</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">  
            <Link to={row.status !== '2' ? `/agent/${row.agent_user_uuid}` : "javascript:void(0)"}>
              <div className="profile-sec">
                <img className="" src={row.profile_image || dummyProfile} />
                <div>
                  <p>{`${row.first_name} ${row.last_name}`}</p>
                </div>
              </div>
            </Link>
         
            </div>),      
        },
        {
            name: <div className="datatable-hd"><h6>Email</h6></div>,
            selector: row => (
              <div data-title="" className="numeric data-table-sec">
                <p>{row.email}</p>              
              </div>),           
        },
        {
          name: <div className="datatable-hd"><h6>Phone Number</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">            
              <span>{row.phone_number}</span>
            </div>
          ),    
      },   
      {
        name: <div className="datatable-hd"><h6>Status</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
            {statusOptions.map(option => {
              return row.status === option.value && (
                <span key={option.value} className={option.className}>{option.label}</span>
              );
            })}
          </div>
        ),
      },   
      {
          name:<div className="datatable-hd"><h6>Created On</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">      
            <span>{format(new Date(row.created_at), 'dd/MM/y')}</span>
          </div>),   
      }, 
      {
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">           
               {(() => {
                  switch (row.status) {
                    case "0":
                      return (
                        <>
                          <li><a href="javascript:void(0)"  className="dropdown-item success" onClick={() => changeStatus(row.agent_user_uuid,'activate')}><i className="fa-solid  fa-square-check me-2"></i>Activate</a></li>
                          <li><Link to={`/agent/${row.agent_user_uuid}`} className="dropdown-item"><i className="fa-solid fa-eye me-2"></i>View</Link></li>
                        </>                      
                      );
                    case "1":
                      return ( 
                           <>                                         
                          <li><a href="javascript:void(0)"  className="dropdown-item reject" onClick={() => changeStatus(row.agent_user_uuid,'deactivate')} ><i className="fa-regular fa-trash-can me-2"></i>Deactivate</a></li>   
                          <li><a href={`/agent/${row.agent_user_uuid}`} className="dropdown-item"><i className="fa-solid fa-eye me-2"></i>View</a></li>                     
                          </>
                         );
                      case "2":
                      return (
                        <li><a href="javascript:void(0)"  className="dropdown-item reject" onClick={() => changeStatus(row.agent_user_uuid,'delete')} ><i className="fa-regular fa-trash-can me-2"></i>Delete</a></li>                         
                      );                 
                  }
                })()}
              </ul>
            </div>
          </div>
        </div>
      ),
              } 
      
  ];

  return (
    <>
     <div class="tab-pane fade show active" id="subagents-tab-pane" role="tabpanel" aria-labelledby="subagents-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
      <div class="col-lg-4 mb-3 text-center text-lg-start">
            <h4 class="mt-3">Agents</h4>
          </div>
          <div class="col-lg-8 mb-3">
            <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
               {filters?.map((filter, index) => (
                  <div className="sort-sec my-2 me-2" key={index}>
                    <p className="me-1">{filter.title} : </p>
                    <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                      {filter.options?.map( option => 
                        <option value={option.value} >{option.option}</option>
                      )}                        
                    </select>
                  </div>
                ))}
            
              <button type="button" class="btn btn-primary my-2" data-bs-toggle="modal" data-bs-target="#agent-tab-modal" onClick={handleShow}> Add Agent</button>
            </div>
          </div>
        </div>
        <div className="">          
            <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}  tableData={subAgents}  type ={'agents'} isTableLoaded={isTableLoaded} />    
        </div>
      </div>
      <CreateEditModal {...{ showEdit, handleCloseEdit,isEdit, agentDetails,agentData,perPage, isSuperAgent,superAgentId }} />
    </>
  );
};


export default ListingComponent;

import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext, Controller } from "react-hook-form";
import addImg from "../../Assets/Images/add-image.svg";
import { MakeFileUpload } from "../../utils/handler";
import Compressor from "compressorjs";
import loaderFile from "../../Assets/Images/loader.gif";

function AddImageField(props) {
  const {
    control,
    formState: { errors },
    setError
  } = useFormContext();

  const [files, setFiles] = useState([]);
  const [fileRejected, setFileRejected] = useState(false); // Track if any files are rejected
  const [isUploading, setIsUploading] = useState(false);
  let pendingCount = 0;

  const onDropRejected = () => {
    // Handle file rejection here
    setFileRejected(true);
  };

  useEffect(() => {
    setTimeout(() => {
      fileRejected && setFileRejected(false);
    }, 3000);
  }, [fileRejected])
  
  const tempFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file,file.name);
      const response = await MakeFileUpload("post", formData, "/imageUpload");
      if (response.status === 1) {
        props.setFilesKey((prevKeys) => [...prevKeys, response.data.temp_key]);
        setFiles((prevFiles) => [...prevFiles, response.data]);
        // console.log(response.data);
        pendingCount = pendingCount-1;
        props.setSubmitDisabled(pendingCount>0);
        setIsUploading(pendingCount>0)
      } else {
        console.log(response.message);
        pendingCount = pendingCount-1;
        props.setSubmitDisabled(pendingCount>0);
        setIsUploading(pendingCount>0)
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    pendingCount = pendingCount+acceptedFiles.length;
    // console.log(acceptedFiles);
    props.setSubmitDisabled(pendingCount>0);
    setIsUploading(pendingCount>0)
    // acceptedFiles.forEach((file) => {
    for (const file of acceptedFiles) {
        // Check if the file is a video
      if (file.type.startsWith("video/")) {
        // Get duration of video
        const duration = await getVideoDuration(file);
        // Check if duration is less than or equal to 30 seconds
        if (duration <= 31) {
          tempFileUpload(file);
        } else {
          console.log(duration);
          pendingCount = pendingCount-1;
          props.setSubmitDisabled(pendingCount>0);
          setIsUploading(pendingCount>0);
          setError(props.name, { type: 'custom', message: "Video duration should be less than or equal to 30 seconds." });
          // alert("Video duration should be less than or equal to 30 seconds.");
        }
      }else if (file.type.startsWith("image/") && (file.size > 5000000)) {
        new Compressor(file, {
          quality: 1,
          width: 2000,
          height: 2000,
          success: (compressedResult) => {
            tempFileUpload(compressedResult);
          },
        });
      } else {
        tempFileUpload(file);
      }
    }
    // });
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      onDropRejected, // Handle rejected files when selected via clicking
      accept: props.acceptedFileTypes || {"image/*": []}, // Accept the file types passed through props or default to images
    });

  const removeFile = (key) => {
    setFiles((prevFiles) => prevFiles.filter((i) => i.temp_key !== key));
    props.setFilesKey((prevKeys) => prevKeys.filter((i) => i !== key));
  };

  // Function to get video duration
  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.onloadedmetadata = () => {
        resolve(video.duration);
      };
      video.onerror = reject;
      video.src = URL.createObjectURL(file);
    });
  };

  // useEffect(() => {
  //   console.log(files, "files");
  // }, [files]);

  // useEffect(() => {
  //   console.log(props.filesKey, "filesKey");
  // }, [props.filesKey]);

  return (
    <div className={props.className}>
      {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
        </label>
      )}
    
      <div className="upload-img" {...getRootProps()}>
        <input {...getInputProps()}/>
        <img src={addImg} />
        {/* <h6 className="mt-3 mb-0">{props.title}</h6> */}
        <h6 className="mt-3 mb-0">
          {/* {isDragActive ? ("Drop the files here ...") : props.title } */}
          {/* {(isDragReject && <span className="error">{props.dropzoneMsg}</span>) ||
            (isDragActive && "Drop the files here ...") ||
            props.title} */}
            {((isDragReject || fileRejected) && <span className="error">{props.dropzoneMsg}</span>) || props.title}
        </h6>
        <span className="small gray">{props.subtitle}</span>
      </div>
      {/* </a> */}

      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        rules={props.rules}
        render={({ field }) => (
          <input {...field} style={{ display: "none" }} type="text" />
        )}
      />
      {files?.length > 0 && (
        <div className="d-flex align-items-center flex-wrap justify-content-center">
          {files.map((file, index) => (
            <div key={index} className="services-imgs modal-category-img my-2 me-3">
              {/* <img className="" src={URL.createObjectURL(file)} /> */}
              {(() => {
                switch (file.filetype) {
                    case "image":
                    return (
                      <img className="" src={file.image_path} loading="lazy"/>
                    );
                    case "video":
                    return (
                      <video className="" src={file.image_path} controls preload="metadata">
                        Your browser does not support the video tag.
                      </video>
                    );
                    default:
                    return (
                      <iframe
                        src={file.image_path}
                        width="150px"
                        height="150px"
                        title="Preview"
                      />
                    );
                }
                })()}
              {props.hideRemovebtn || (
                <a
                  href="javascript:void(0)"
                  className="close-btn"
                  onClick={() => removeFile(file.temp_key)}
                >
                  <i className="fa-regular fa-circle-xmark"></i>
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      {isUploading && (
            <div className="modal-loader">
              <img
                className="loader"
                src={loaderFile}
                style={{ width: "120px" }}
                alt="Loading..."
              />
            </div>
      )}
      {errors[props.name] && (props.filesKey.length == 0) && (
        <span className="error">{errors[props.name].message}</span>
      )}
    </div>
  );
}

export default AddImageField;

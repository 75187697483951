import React, {useState, useEffect} from 'react';
import { MakeAxiosRequest } from '../../../utils/handler';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import loaderFile   from '../../../Assets/Images/loader.gif';
import DataTableList from "../../../Components/Datatablelist/Datatablelisting";
import logo from '../../../Assets/Images/logo.png'
import { format } from 'date-fns';
import { useBreadcrumb } from "../../../Components/Breadcrumbs/BreadcrumbContext";
import { CSVLink } from 'react-csv';
import html2pdf from 'html2pdf.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import UserRowActions from '../../../Components/Datatablelist/Tablerowactions';



function Listing({searchValue,setsearchValue}) {
    const navigate = useNavigate();
    const params = useParams();

    const selectedOption = params.type || 'all';
    const start_date = params.startdate;
    const end_date = params.enddate;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isdownloadedLoaded, setIsdownloadedLoaded] = useState(true);
    const [isModalLoaded, setModalIsLoaded] = useState(false);
    const [getpaymentReports, setPaymentReports] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paymentDetails, setPaymentDetails] = useState();
    const [filters, setFilters] = useState([]);
    const [transactionTypesFilters, SetTransactionTypesFilters] = useState([]);
    //const [selectedOption, setSelectedOption] = useState('all');
    const [selsortOption, setSortOption] = useState('newest');
    const { setBreadcrumbsData } = useBreadcrumb();
    const [isTableLoaded, setisTableLoaded] = useState(true);
    const [datePickererror, setDatePickerError] = useState(null);
    const formattedDate = format(new Date(), 'dd/MM/yyyy h:mm a');   
    const csvFileName = `payment-reports-${formattedDate}.csv`;
   
    const [listParams, setListParams] = useState({
      offset: "", sortBy: '',transactiontype:selectedOption,end_date:end_date,start_date:start_date
    });

    const dispatch = useDispatch()
    const authUser = useSelector((state) => state.auth.user);
    const  loginedKey =  authUser.user_uuid;

    const columns = [    
      {
        name: <div className="datatable-hd"><h6>Paid To</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
            <UserRowActions  row={{
                  primaryData: row.first_name+' '+row.last_name,
                  imagePath :row.profile_image,
                  viewUrl:`/agent/${row.agent_user_uuid}`
                }} />
            {/* <div className="profile-sec">
              <img src={row.profile_image} alt="" />
              <div>
                <p>{`${row.first_name} ${row.last_name ? row.last_name : ''}`}</p>
              </div>
            </div> */}
          </div>
        ),
      },      
      {
        ...(selectedOption !== 'payouts' && {
          name: <div className="datatable-hd"><h6>Paid By</h6></div>,
          selector: row => (
            <div data-title="" className="numeric data-table-sec">
              {
                row.business_uuid ? 
                <UserRowActions  
                row={{ 
                  primaryData: row.business_title, 
                  imagePath :row.business_image,          
                  viewUrl: row.business_uuid && `/business/${row.business_uuid}` 
                }}
              /> :
                <div className="profile-sec">
                  <img src={row.business_image} alt="" />
                  <div>
                    <p>{`${row.business_title}`}</p>
                  </div>
                </div>
              }
          </div>
          ),
        }),
      },
      
      {
        name:<div className="datatable-hd"><h6>Transaction Type</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
          <p>{row.type}</p>          
          </div>        
        ),
      },
      {
        name: <div className="datatable-hd"><h6>Transaction Date</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
          {format(new Date(row.created_at),  'dd/MM/y')}
          </div>
        
        ),
      },  
      {
        name: <div className="datatable-hd"><h6>Amount</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
          <p className={row.payout_type_id === 3 ? 'debit-amt' : 'credit-amt'}>
          {row.payout_type_id === 3 ? <span><i class="fa-solid fa-minus"></i></span> : <span><i class="fa-solid fa-plus"></i></span>} <i className="fa-solid fa-indian-rupee-sign"></i> {row.amount}
         </p>

          </div>
        
        ),
      },  
      {
        selector: row => (
          <div data-title="" className="numeric scrolview data-table-sec">
            <div className="d-flex justify-content-end">
              <div className="btn-group option-btn">
                <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                  <i className="fa-solid fa-ellipsis"></i>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                <a  href="javascript:void(0)" className="dropdown-item danger"
            onClick={() => handleDownloadPdf(row.payout_uuid,'download')}>
            <i className="fa-solid fa-download me-2"></i>Download
          </a>  
                     <li><a href='javascript:void(0)' className="dropdown-item" onClick={() => viewPaymentReport(row.payout_uuid)} ><i className="fa-solid fa-eye me-2"></i>View</a></li>       
              
                </ul>
              </div>
            </div>
          </div>
        ),
      },
    ]; 



  // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData([     
        { label: "Reports" },
        { label: "Payment Reports"},
        { label: "Listing"},       
      ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 
   

  

  
  const fetchData = async () => {      
    try {      
       const response = await MakeAxiosRequest("post", {...listParams, searchValue}, "/paymentreports");     
       setPaymentReports(response.data.payouts); 
       setIsLoaded(true);
       setisTableLoaded(true);
       setTotalRows(response.data.totalcount);
       setFilters(response.data.filters); 
       SetTransactionTypesFilters(response.data.transactionTypes); 
    } catch (error) {      
       console.error('Error fetching data:', error);
    }
  };
    

  useEffect(() => {
      fetchData();
  }, [listParams,searchValue])


 const handlePageChange = page => {

  const newOffset = (page - 1) ;

  setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));
  
}

const viewPaymentReport = async (reportKey) => {
  $('#payment-report-modal').modal('show');   
  setModalIsLoaded(false); 
  try {
    const response = await MakeAxiosRequest("post", {}, `/paymentreport/${reportKey}/details`);     
    setPaymentDetails(response.data.payoutdets); 
    setModalIsLoaded(true); 
  } catch (error) {
    console.error(error);
  }
};


  // const handleSelectChange = (filterName,value) => {   
  //   setIsLoaded(false);
  //   setSortOption(value);    
  //   setListParams(prevParams => ({ ...prevParams,  [filterName]: value, }));
  // };

  const handleClickChange = (filterName,value) => {  
    setsearchValue(''); 
    setisTableLoaded(false);
    //setSelectedOption(value);
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value,offset:'0' }));
    if (startDate && endDate) {
      navigate('/paymentreports/'+value+'/'+listParams.start_date+'/'+listParams.end_date);  
    }else{
      navigate('/paymentreports/'+value);  
    }
  };
 let  htmlTag ='';
 const [paymentDet, setpayoutdets] = useState();
  const handleDownloadPdf = async (reportKey,type='') => {
    try {
    if(type=='download'){
      setIsdownloadedLoaded(false);
      const response = await MakeAxiosRequest('post', {}, `/paymentreport/${reportKey}/details`);
          // setPaymentDetails(response.data.payoutdets); 
          setpayoutdets(response.data.payoutdets); 
     //   paymentDet = response.data.payoutdets;
      // <PaymentReceiptDetails paymentDet={paymentDet} /> 
    htmlTag= 'html-content';
    }else{
      htmlTag= 'pdf-content';
    }
  setTimeout(() => {
    const hiddenContent = document.getElementById(htmlTag);
    const content = hiddenContent.innerHTML;
   // console.log('Content:', content); 
  
    if (content) {
      html2pdf(content, {
        margin: 10,
        filename: 'payment_receipt_' + format(new Date(), 'dd/MM/y') + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });
      setIsdownloadedLoaded(true);
    } else {
      console.error('Failed to find content for PDF generation');
    }
  }, 500); 
    
     
 

  
} catch (error) {
  console.error('Error fetching payment details:', error);
}
};
  


  const [startDate, setStartDate] = useState(start_date && new Date(start_date));
  const [endDate, setEndDate] = useState(end_date && new Date(end_date));

  const handleStartDateChange = (date) => {
    setStartDate(date);  
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);   
  };


  //Function to format the date to IST
  const formatToIST = (date) => {
    const options = { timeZone: 'Asia/Kolkata' };
    return date.toLocaleString('en-US', options);
  };

  const handleDateChange = () => {
    let errMsg = '';
    if (startDate && endDate) {
      setisTableLoaded(false);
      setDatePickerError(null);
  
      let formattedStartDate = formatToIST(startDate);
      let formattedEndDate = formatToIST(endDate);
  
      formattedStartDate = format(new Date(formattedStartDate), 'y-MM-dd');
      formattedEndDate = format(new Date(formattedEndDate), 'y-MM-dd');
  
      setListParams((prevParams) => ({
        ...prevParams,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        offset:'0'
      }));
      navigate('/paymentreports/'+listParams.transactiontype+'/'+formattedStartDate+'/'+formattedEndDate); 
    } else {
      if(!startDate && !endDate){
        errMsg = 'start date and end date';
      }else  if (!startDate) {
        errMsg = 'start date';
      } else if (!endDate) {
        errMsg = 'end date';
      }
      setDatePickerError(
        <span className="error">Please choose the {errMsg}</span>
      );
    }
  };
  



  
  
  
 

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>

<div id="html-content" style={{display: "none"}}>
  <div className="row" >
                        <div className="col-12 my-4">
                          <div className="text-center">
                               <img className='mb-3' src={logo} class='logo-aldz mb-2' /> 
                               <h4>Payment Receipt</h4>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">

                      
                      <h5 className='primary'>Payment Details</h5>
                      <hr />
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3 ">
                          <p className='mb-0'>Receipt ID </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDet ? `${paymentDet.payout_num} ` : '--'}</p>
                          </div>
                        </div>
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3 ">
                          <p className='mb-0'>Transaction Type </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDet ? `${paymentDet.type} ` : '--'}</p>
                          </div>
                        </div>
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3 ">
                          <p className='mb-0'>Transaction Date </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDet && paymentDet.created_at !=null ? `${format(new Date(paymentDet.created_at),  'dd/MM/y')}` : '--'}</p>
                          </div>
                        </div>
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3">
                          <p className='mb-0'>Paid By </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDet ? `${paymentDet.paid_by} ` : '--'}</p>
                          </div>
                        </div>
                        <div className="row align-items-center">                     
                          <div class="col-md-6 col-lg-6 mb-3">
                          <h5 className='mb-0'><strong>Amount</strong></h5>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <h5 className='mb-0'><strong>{paymentDet ? (
                                  <>
                                    <i className="fa-solid fa-indian-rupee-sign"></i> {paymentDet.amount}
                                  </>
                                ) : (
                                  '--'
                                )}</strong></h5>
                          </div>
                        </div>
                        </div>
                      </div>
                            <div className='col-12'>
                           <h5 className='primary'>Billing Info</h5> <hr/> 
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Name</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDet ? `${paymentDet.first_name} ${paymentDet.last_name}` : ''}</p> 
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Email</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDet ? `${paymentDet.email} ` : '--'}</p> 
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Address</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDet ? `${paymentDet.address}` : '--'}</p> 
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Phone Number</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDet ? `${paymentDet.phone_number}` : '--'}</p> 
                                    </div>
                                  </div>
                           
                           

                          </div>


  </div>


    
    <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-xl-5 mb-3">
            <div className="d-flex justify-content-center justify-content-xl-start">
                <ul className="sort-btn-group">
                   {transactionTypesFilters?.flatMap((filter) => filter.options)?.map((option, optionIndex) => (
                      <li key={optionIndex}>
                        <a  className={`btn tabview ${option.value === selectedOption ? 'active' : ''}`}   onClick={() => handleClickChange(transactionTypesFilters[0].type, option.value)}>
                          {option.option}  
                        </a>
                      </li>
                    ))}
                </ul>
            </div>
          </div>
        <div className="col-xl-7 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-xl-end flex-wrap">
          <div className="activity-log-date me-3">
              <div className='d-flex justify-content-center flex-wrap'>
            <div class=" date-sort my-2 me-2 position-relative"> 
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                // maxDate={new Date()}
                maxDate={endDate || new Date()}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="form-control"
                dateFormat="d/MM/y" 
              />
              <span className='date-icon'><FaCalendarAlt className="calendar-icon" /></span>
              
               
                  </div>
                  <div class=" date-sort my-2 me-2 position-relative"> 
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      maxDate={new Date()}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      className="form-control"
                      dateFormat="d/MM/y" 
                    /> 
                    <span className='date-icon'><FaCalendarAlt className="calendar-icon" /></span>
                  </div>
                 
                  <button type="button" class="btn btn-outline-primary mt-2"  onClick={(e) => handleDateChange()} data-bs-dismiss="modal">Go</button>
                 
            </div>
            {datePickererror}  
            </div>
       {getpaymentReports && getpaymentReports.length > 0 && (
          <CSVLink          
            data={selectedOption === 'payouts'
            ? getpaymentReports.map(item => ({
                'Paid To' : `${item.first_name} ${item.last_name ? item.last_name : ''}`,
                'Transaction Type': item.type || '--',
                'Transaction Date': `${format(new Date(item.created_at),  'dd/MM/y')}` || '--',
                'Amount': `₹ ${item.amount}` || '--',
              }))
            : getpaymentReports.map(item => ({
                  'Paid To' : `${item.first_name} ${item.last_name ? item.last_name : ''}`,
                  'paid By': item.business_title || '--',
                  'Transaction Type': item.type || '--',
                  'Transaction Date': `${format(new Date(item.created_at),  'dd/MM/y')}` || '--',
                  'Amount': `₹ ${item.amount}` || '--',
              }))
          }
            filename={csvFileName}
            className="btn btn-primary my-2"  >
            Export
          </CSVLink>
        )} 
         
            
          </div>
          </div>
        </div>    
      
        <div className="">
     
        <DataTableList  pageChange={handlePageChange}   totalCount={totalRows}  columns={columns}   tableData={getpaymentReports}  type ={'payment reports'}  isTableLoaded ={isTableLoaded}/>  
          {!isdownloadedLoaded ? (
              <div className='dwnld-lodr'>
                  <img src={loaderFile} style={{ width: '125px' }} alt="Loading..." />
              </div>
          ) : null}
        </div>

             {/* Payout Modal */} 
             <div className="modal fade" id="payment-report-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  {isModalLoaded ? (
                    <div className="modal-body payment-modal-body" >
                      <div id="pdf-content">
                      <div className="row" >
                        <div className="col-12 my-4">
                          <div className="text-center">
                               <img className='mb-3' src={logo} class='logo-aldz mb-2' /> 
                               <h4>Payment Receipt</h4>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">

                      
                      <h5 className='primary'>Payment Details</h5>
                      <hr />
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3 ">
                          <p className='mb-0'>Receipt ID </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDetails ? `${paymentDetails.payout_num} ` : '--'}</p>
                          </div>
                        </div>
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3 ">
                          <p className='mb-0'>Transaction Type </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDetails ? `${paymentDetails.type} ` : '--'}</p>
                          </div>
                        </div>
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3 ">
                          <p className='mb-0'>Transaction Date </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDetails && paymentDetails.created_at !=null ? `${format(new Date(paymentDetails.created_at),  'dd/MM/y')}` : '--'}</p>
                          </div>
                        </div>
                        <div className="row">                     
                          <div class="col-md-6 col-lg-6 mb-3">
                          <p className='mb-0'>Paid By </p>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <p className='mb-0'>{paymentDetails ? `${paymentDetails.paid_by} ` : '--'}</p>
                          </div>
                        </div>
                        <div className="row align-items-center">                     
                          <div class="col-md-6 col-lg-6 mb-3">
                          <h5 className='mb-0'><strong>Amount</strong></h5>
                          </div>
                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <h5 className='mb-0'><strong>{paymentDetails ? (
                                  <>
                                    <i className="fa-solid fa-indian-rupee-sign"></i> {paymentDetails.amount}
                                  </>
                                ) : (
                                  '--'
                                )}</strong></h5>
                          </div>
                        </div>
                        </div>
                      </div>
                            <div className='col-12'>
                           <h5 className='primary'>Billing Info</h5> <hr/> 
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Name</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDetails ? `${paymentDetails.first_name} ${paymentDetails.last_name}` : ''}</p> 
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Email</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDetails ? `${paymentDetails.email} ` : '--'}</p> 
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Address</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDetails ? `${paymentDetails.address}` : '--'}</p> 
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <p className='mb-0'>Phone Number</p> 
                                    </div>
                                    <div className="col-md-6 mb-3 text-end">
                                      <p className='mb-0'>{paymentDetails ? `${paymentDetails.phone_number}` : '--'}</p> 
                                    </div>
                                  </div>
                           
                           

                          </div>
                        </div>
                        <div className="modal-footer px-0">
                          <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal"> Cancel </button>
                          <button type="button" className="btn btn-primary"  onClick={() => handleDownloadPdf(paymentDetails.payout_uuid,'view')}> Download </button>
                          {/* <DownloadPdfButton paymentDetails={paymentDetails} /> */}
                        </div>

                    </div>
                  ) : (
                    <div>
                      <center>
                        <img src={loaderFile} style={{ width: '125px' }} alt="Loading..." />
                      </center>
                    </div>
                  )}
                </div>
              </div>
            </div>
 



      </>
    );
  }
}

export default Listing;

import React, { useEffect, useState } from "react";
import noRecords from "../../Assets/Images/no-records.png";
import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import { MakeAxiosRequest } from "../../utils/handler";
import "chart.js/auto"; // Import 'chart.js/auto' instead of 'chart.js'
import "chartjs-adapter-date-fns"; // Import the date-fns adapter
import dummyProfile from "../../Assets/Images/dummy-profile.jpg";
import loaderFile  from '../../Assets/Images/loader.gif';
import { isEmpty } from "lodash";


const ListingComponent = ({ overviewData, changeTabList }) => {
  const viewData = overviewData["agentuserdets"];
  let businessData = overviewData["businessArr"];
  const graphData = overviewData?.graphArr || [];

  const months = graphData.map((item) => item.month);
  const counts = graphData.map((item) => item.count);

  const data = {
    labels: months,
    datasets: [
      {
        label: "Total Agents",
        data: counts,
        fill: true,
        backgroundColor: "#df7171", // Light red fill color with opacity
        borderColor: "#f22727", // Red border color
        borderWidth: 2,
        pointBackgroundColor: "#fff", // Purple points
        pointRadius: 4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        labels: months,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleClick = () => {
    changeTabList("business");
  };

  return (
    <>
      
        <div
          class="tab-pane fade show active"
          id="overview-tab-pane"
          role="tabpanel"
          aria-labelledby="overview-tab"
          tabindex="0"
        >
          <div class="row">
            <div class="col-12 pt-4">
              <div class="overview-sec">
                <h4 class="mb-3">Overview</h4>
                <div class="row">
                  <div class="col-sm-6 col-xl-4 mb-3">
                    <div class="card ovrview-cards">
                      <div class="card-body">
                        <p>Account Balance</p>
                        <h3>₹{viewData?.account_balance || 0}</h3>
                        <span> Balance Amount to be Paid</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4 mb-3">
                    <div class="card ovrview-cards account-card">
                      <div class="card-body">
                        <p>Commission Amount</p>
                        <h3>₹{viewData?.lastmonthcommission || 0}</h3>
                        <span>Commission for the Current Month</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4 mb-3">
                    <div class="card ovrview-cards commission-card">
                      <div class="card-body">
                        <p>Last Payout</p>
                        <h3>₹{viewData?.last_payout || 0}</h3>
                        <span>
                          Last Updated:{" "}
                          {viewData && viewData.last_payout_date !== ""
                            ? viewData.last_payout_date
                            : "----"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  {businessData && businessData.length > 0 && (
                    <div class="col-xl-5 mb-3">
                      <div class="card h-100">
                        <div class="card-body service-sec">
                          <div class="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                            <h3 class="mb-0">Renewal Pending</h3>
                          </div>
                          {businessData.map((business, index) => (
                            <div
                              class="subcribe-list service-list d-flex align-items-center justify-content-between"
                              key={index}
                            >
                              <div class="d-flex align-items-center">
                                <img
                                  className=""
                                  src={business.image_path || dummyProfile}
                                />
                                <div>
                                  <p>{business.title}</p>
                                  <p class="over-due">
                                    Overdue by {business.overdue} days
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* <a href="javascript:void(0)" data-bs-target="#agent-modal" onClick={handleClick} class="link mt-4 d-block">View all business<i class="fa-solid fa-arrow-right-long ms-2"></i></a> */}
                        </div>
                      </div>
                    </div>
                  )}
                  {graphData && graphData.length > 0 && (
                    <div class="col-xl-12 mb-3">
                      <div class="row mb-3">
                        <div class="col-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="d-flex align-items-center justify-content-between mb-4">
                                <h3 class="mb-0">Agents</h3>
                              </div>
                              <div>
                                <div id="area-chart">
                                  {/* <div style={{ position: 'relative', width: '100%', height: 'auto', maxWidth: '600px' }}> */}
                                  <Line
                                    data={data}
                                    height={350}
                                    options={{
                                      ...options,
                                      plugins: {
                                        title: {
                                          display: true,
                                          text: "Note: This graph represents the total number of agents under the superagents.",
                                          position: "bottom",
                                        },
                                        legend: {
                                          display: true,
                                        },
                                      },
                                      maintainAspectRatio: false, // Disable aspect ratio for responsiveness
                                      scales: {
                                        y: {
                                          suggestedMin: 0, // Minimum value for the y-axis
                                          beginAtZero: true, // Start the y-axis at zero
                                          ticks: {
                                            stepSize: 1
                                            // precision: 0
                                          }
                                        }
                                      }
                                    }}
                                  />

                                  {/* </div> */}
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div className="col-lg-8 mx-auto">
                                  <div className="row">
                                    <div class="col-md-6 mb-2">
                                      <div class="card">
                                        <div class="card-body dash-counts sbscrb-counts">
                                          <h5>
                                            {viewData &&
                                              viewData.new_agent_count}
                                          </h5>
                                          <span>New Agents</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                      <div class="card">
                                        <div class="card-body dash-counts sbscrb-counts">
                                          <h5>
                                            {viewData && viewData.agent_count}
                                          </h5>
                                          <span>Total Agents</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default ListingComponent;
